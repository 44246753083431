import React, { Component } from 'react';
// import './style.css';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import whatsappSvg from '../assets/icons/whatsapp.svg';
import instagramSvg from '../assets/icons/instagram.svg';
import twitterSvg from '../assets/icons/twitter.svg';
import facebookSvg from '../assets/icons/facebook.svg';
import logo from '../assets/images/logo.png';

class Navbar extends Component {

    state = {
        loading: true
    };

    componentDidMount() {
        this.fakeRequest().then(() => {
            const el = document.querySelector(".loader-container");
            if (el) {
                el.remove();  // removing the spinner element
                this.setState({ loading: false }); // showing the app
            }
        });
    }
    
    fakeRequest = () => {
        return new Promise(resolve => setTimeout(() => resolve(), 2500));
    };

    openNav =() =>{
        document.getElementById("myNav").style.width = "100%"
    }
    closeNav =() =>{
        document.getElementById("myNav").style.width = "0%"
    }

    render() {
        if (this.state.loading) {
            return null; //app is not ready (fake request is in process)
          }
        return(
            <div>
                <div id="myNav" className="new-navbar-overlay">
                    <a className="closebtn" style={{ cursor: "pointer" }} onClick={this.closeNav}>&times;</a>
                    <div className="new-navbar-overlay-content">
                        <Link to="/" onClick={this.closeNav}>Home</Link>
                        <Link to="/About" onClick={this.closeNav}>About</Link>
                        <Link to="/Portfolio" onClick={this.closeNav}>Portfolio</Link>
                        <Link to="/Careers" onClick={this.closeNav}>Careers</Link>
                        <Link to="/newContact" onClick={this.closeNav}>Contact</Link>
                        <a className="ml-2" style={{ display: "inline" }} href="https://api.whatsapp.com/send?phone=2347051372500"><img src={whatsappSvg} width="35px" height="35px" alt="whatsapp" /></a>
                        <a className="ml-2" style={{ display: "inline" }} href="https://twitter.com/toniedesigns"><img src={twitterSvg} width="35px" height="35px" alt="twitter" /></a>
                        <a className="ml-2" style={{ display: "inline" }} href="https://www.facebook.com/toniedesignsatelier/"><img src={facebookSvg} width="36px" height="36px" alt="facebook"/></a>
                        <a className="ml-2" style={{ display: "inline" }} href="https://www.instagram.com/toniedesignsatelier/"><img src={instagramSvg} width="35px" height="35px" alt="instagram"/></a>
                        
                    </div>
                </div>
                {/* <div className="nav-row" style={{marginBottom: "0", marginTop:"1%", marginLeft:"2%"}}>
                        <a><span style={{ fontSize: "40px", cursor: "pointer", paddingTop:"10%" }} onClick={this.openNav}>&#9776;</span></a>
                        <a className="text-center" style={{ paddingLeft:"40%" }}><img src={logo} width="80px" height="75px" alt="logo" /></a>
                </div> */}
                <nav className="navbar navbar-transparent" style={{ zIndex:"2", backgroundColor:"transparent", background: 'rgba(0,0,0, 0.5)'}}>
                    <a><span style={{ fontSize: "40px", cursor: "pointer", paddingTop: "10%", width:"50px"}} onClick={this.openNav}>&#9776;</span></a>
                    <Link to="/" onClick={this.closeNav}><a className="logo"><img src={logo} width="210px" height="150px" alt="logo" /></a></Link>
                    <a><span style={{ fontSize: "40px", cursor: "pointer", paddingTop: "10%",color:"transparent" }} ><i className="fa fa-envelope" /></span></a>
                </nav>
            </div>
        )
        
    }
}
export default Navbar;

import React, { Component } from 'react';
// import Slider from 'react-slick';
// import Slides from '.../components/Slides';
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/custom-animations/open-animation.css';
import 'react-awesome-slider/dist/styles.css';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import { Link } from 'react-router-dom';

const AutoplaySlider = withAutoplay(AwesomeSlider)
class PrivateRenovation extends Component {
    render() {
        return (
            <div className="container" style={{ marginTop: "-3%" }}>
                <div className="row">
                    <div className='col-12'>
                        <Link to="/portfolio" style={{ color: "red" }} className="float-right"><h4><i className="fa fa-arrow-left" /></h4></Link>
                    </div>
                    {/* <div className='col-12'>
                    <h6 style={{ color:"white" }}>Construction Of A duplex and 4 Bedroom Flat at Abeokuta</h6>
                    </div> */}
                </div>
                <div className="row">
                    <div className="col-lg-2 col-md-12 col-sm-12 col-xs-12">
                    </div>
                    <div className="col-lg-8 col-md-12 col-sm-12 col-xs-12">
                        <h6 style={{ color: "white" }}>3D Visualization for proposed Foodcity outlet at Lawanson, Lagos.</h6>
                        <AutoplaySlider animation="openAnimation"
                            play={true}
                            cancelOnInteraction={false} // should stop playing on user interaction
                            interval={3000}
                            bullets={true}
                        >   <div data-src="https://res.cloudinary.com/toniedesignsatelier/image/upload/v1648073477/Portfolio/Renovation%20/1_tmtu9r.jpg" alt="Image 4" />
                            <div data-src="https://res.cloudinary.com/toniedesignsatelier/image/upload/v1648073477/Portfolio/Renovation%20/4_js9rrv.jpg" alt="Image 3" />
                            <div data-src="https://res.cloudinary.com/toniedesignsatelier/image/upload/v1648073476/Portfolio/Renovation%20/3_zhihdr.jpg" alt="Image 2" />
                            <div data-src="https://res.cloudinary.com/toniedesignsatelier/image/upload/v1648073476/Portfolio/Renovation%20/2_muitkq.jpg" alt="Image 1">
                            </div>
                            
                        </AutoplaySlider>
                    </div>
                    <div className="col-lg-2 col-md-12 col-sm-12- col-xs-12">
                    </div>
                </div>
            {/* </div> */}
                </div>
        )
    }
}
export default PrivateRenovation;
import React, { Component } from 'react';
// import Slider from 'react-slick';
// import Slides from '.../components/Slides';
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/custom-animations/open-animation.css';
import 'react-awesome-slider/dist/styles.css';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import { Link } from 'react-router-dom';

const AutoplaySlider = withAutoplay(AwesomeSlider)
class Lawanson extends Component {
    render() {
        return (
            <div className="container" style={{ marginTop: "-3%" }}>
                <div className="row">
                    <div className='col-12'>
                        <Link to="/portfolio" style={{ color: "red" }} className="float-right"><h4><i className="fa fa-arrow-left" /></h4></Link>
                    </div>
                    {/* <div className='col-12'>
                    <h6 style={{ color:"white" }}>Construction Of A duplex and 4 Bedroom Flat at Abeokuta</h6>
                    </div> */}
                </div>
                <div className="row">
                    <div className="col-lg-2 col-md-12 col-sm-12 col-xs-12">
                    </div>
                    <div className="col-lg-8 col-md-12 col-sm-12 col-xs-12">
                        <h6 style={{ color: "white" }}>3D Visualization for proposed Foodcity outlet at Lawanson, Lagos.</h6>
                        <AutoplaySlider animation="openAnimation"
                            play={true}
                            cancelOnInteraction={false} // should stop playing on user interaction
                            interval={3000}
                            bullets={true}
                        >   <div data-src="https://res.cloudinary.com/toniedesignsatelier/image/upload/v1648069422/Portfolio/LawansonConstruction/2_orj3ts.jpg" alt="Image 4" />
                            <div data-src="https://res.cloudinary.com/toniedesignsatelier/image/upload/v1648069260/Portfolio/LawansonConstruction/1_qsjea5.jpg" alt="Image 3" />
                            <div data-src="https://res.cloudinary.com/toniedesignsatelier/image/upload/v1596317563/Portfolio/Commercial/Food%20City%20Lawanson/Untitled_38_jgfg1i.jpg" alt="Image 2" />
                            <div data-src="https://res.cloudinary.com/toniedesignsatelier/image/upload/v1595834431/Portfolio/Commercial/Food%20City%20Lawanson/Proposed-Foodcity-Restaurant-Lawanson-3_ekprrw.jpg" alt="Image 1">
                            </div>
                            <div data-src="https://res.cloudinary.com/toniedesignsatelier/image/upload/v1596317716/Portfolio/Commercial/Food%20City%20Lawanson/Proposed-Foodcity-Restaurant-Lawanson-4_zvltbq.jpg" alt="Image 1">
                            </div>
                            
                        </AutoplaySlider>
                    </div>
                    <div className="col-lg-2 col-md-12 col-sm-12- col-xs-12">
                    </div>
                </div>
            {/* </div> */}
                </div>
        )
    }
}
export default Lawanson;
import React, { Component } from 'react';
import whatsappSvg from '../assets/icons/whatsapp.svg';
import instagramSvg from '../assets/icons/instagram.svg';
import twitterSvg from '../assets/icons/twitter.svg';
import facebookSvg from '../assets/icons/facebook.svg';
import pinterestSvg from '../assets/icons/pinterest.svg';
import linkedinSvg from '../assets/icons/linkedin.svg';
import * as emailjs from 'emailjs-com';



let messageAlert = () => {
    alert("Thanks, message sent")
}


class newContact extends Component {
    state = {
        name: '',
        email: '',
        message: '',
    }

    handleSubmit(e) {
        e.preventDefault()
        const { name, email, message } = this.state
        let templateParams = {
            from_name: name,
            to_name: 'Tonie Designs Atelier',
            message_html: message,
            from_email: email,
        }
        emailjs.send(
            'smtp_server',
            'template_GVuJRD68',
            templateParams,
            'user_JdIwLaWEx89gxKaqEIjP1'
        )
        this.resetForm()
        messageAlert()
    }
    resetForm() {
        this.setState({
            name: '',
            email: '',
            message: '',
        })
    }
    handleChange = (param, e) => {
        this.setState({ [param]: e.target.value })
    }
    render() {
        return (
            <div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 mb-5">
                            <div className="col-12">
                                <h1 className="mb-5">
                                    Reach us
                                    </h1>
                            </div>
                            <div className="row">
                                <div className=" col-12 mb-3"><h5><i className="fa fa-phone  mr-2" style={{ fontSize: "40px" }} /> +2347051372500 +2347033751359</h5></div>
                                <div className="mb-3 col-12"><h5><i className="fa fa-envelope  mr-2" style={{ fontSize: "40px" }} /> toniedesignsatelier@gmail.com</h5></div>
                                <div className="mb-3 col-12"><h5><i className="fa fa-home  mr-2" style={{ fontSize: "45px" }} /> 49, Clegg Street, Surulere Lagos. Nigeria.</h5></div>
                            </div>
                            <div className="row" >
                                <div className="col-4">
                                    <a href="https://api.whatsapp.com/send?phone=2347051372500"><img src={whatsappSvg} width="35px" height="35px" alt="whatsapp" /></a>
                                </div>
                                <div className="col-4 ">
                                    <a href="https://twitter.com/toniedesigns"><img src={twitterSvg} width="35px" height="35px" alt="twitter" /></a>
                                </div>
                                <div className="col-4 ">
                                    <a href="https://www.facebook.com/toniedesignsatelier/"><img src={facebookSvg} width="35px" height="35px" alt="facebook" /></a>
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-4 ">
                                    <a href="https://www.instagram.com/toniedesignsatelier/"><img src={instagramSvg} width="35px" height="35px" alt="instagram" /></a>
                                </div>
                                <div className="col-4 ">
                                    <a href="#"><img src={linkedinSvg} width="35px" height="35px" alt="instagram" /></a>
                                </div>
                                
                                <div className="col-4 ">
                                    <a href="#"><img src={pinterestSvg} width="35px" height="35px" alt="instagram" /></a>
                                </div>
                            </div>
                            
                        </div>
                    
                        <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                            <div className="col-12">
                                <h1 className="mb-5">
                                    Send a message
                                        </h1>
                            </div>
                            <div class="container">
                                <form onSubmit={this.handleSubmit.bind(this)}>
                                    <label for="fname">Your Name</label>
                                    <input type="text" id="name" name="name" placeholder="Your name.."
                                        value={this.state.name}
                                        className="text-white"
                                        onChange={this.handleChange.bind(this, 'name')}
                                        required
                                    />

                                    <label for="email">Email</label>
                                    <input type="email" id="email" name="email" placeholder="Your Email address..."
                                        value={this.state.email}
                                        className="text-white"
                                        onChange={this.handleChange.bind(this, 'email')}
                                        required
                                    />
                                    <label for="subject">Your Message</label>
                                    <textarea id="subject" name="subject" placeholder="Write something.." style={{ height: "auto" }}
                                        value={this.state.message}
                                        className="text-white"
                                        onChange={this.handleChange.bind(this, 'message')}
                                        required
                                    ></textarea>

                                    <input type="submit" value="Submit" onClick={messageAlert} />
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default newContact;
import React, { Component } from 'react';
// import Slider from 'react-slick';
// import Slides from '.../components/Slides';
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/custom-animations/open-animation.css';
import 'react-awesome-slider/dist/styles.css';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import { Link } from 'react-router-dom';

const AutoplaySlider = withAutoplay(AwesomeSlider)
class Cinema extends Component {
    render() {
        return (
            <div className="container" style={{ marginTop:"-3%" }}>
                <div className="row">
                    <div className='col-12'>
                    <Link to="/portfolio"  style={{ color:"red" }} className="float-right"><h4><i className="fa fa-arrow-left" /></h4></Link>
                    </div>
                    {/* <div className='col-12'>
                    <h6 style={{ color:"white" }}>Construction Of A duplex and 4 Bedroom Flat at Abeokuta</h6>
                    </div> */}
                </div>
                <div className="row">
                    <div className="col-lg-2 col-md-12 col-sm-12 col-xs-12">
                    </div>
                    <div className="col-lg-8 col-md-12 col-sm-12 col-xs-12">
                        <h6 style={{ color:"white" }}>3D Visualization of a proposed private cinema</h6>
                        <AutoplaySlider animation="openAnimation"
                            play={true}
                            cancelOnInteraction={false} // should stop playing on user interaction
                            interval={3000}
                            bullets={true}
                        >
                            <div data-src="https://res.cloudinary.com/toniedesignsatelier/image/upload/v1595861865/Portfolio/Commercial/Residential%20Cinema/ome-cinema-lekki-2_fdxoo4.jpg" alt="Image 1">
                                {/* <h1 style={{ zIndex:"3" }}>The answer is -- Don't think about it.</h1> */}
                            </div>
                            <div data-src="https://res.cloudinary.com/toniedesignsatelier/image/upload/v1595861862/Portfolio/Commercial/Residential%20Cinema/ome-cinema-lekki_qffkrq.jpg" alt="Image 2" />
                            <div data-src="https://res.cloudinary.com/toniedesignsatelier/image/upload/v1595861961/Portfolio/Commercial/Residential%20Cinema/Private-Cinema-In-Lagos_k8a069.jpg" alt="Image 3" />
                        </AutoplaySlider>
                    </div>
                    <div className="col-lg-2 col-md-12 col-sm-12- col-xs-12">
                    </div>
                </div>


            </div>
        )
    }
}
export default Cinema;
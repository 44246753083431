import React, { Component } from 'react';
// import Slider from 'react-slick';
// import Slides from '.../components/Slides';
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/custom-animations/open-animation.css';
import 'react-awesome-slider/dist/styles.css';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import { Link } from 'react-router-dom';
import { SRLWrapper } from "simple-react-lightbox";

const AutoplaySlider = withAutoplay(AwesomeSlider)
class Igando extends Component {
    render() {
        return (
            <div className="container" style={{ marginTop:"-3%" }}>
                <div className="row">
                    <div className='col-12'>
                    <Link to="/portfolio"  style={{ color:"red" }} className="float-right"><h4><i className="fa fa-arrow-left" /></h4></Link>
                    </div>
                    {/* <div className='col-12'>
                    <h6 style={{ color:"white" }}>Construction Of A duplex and 4 Bedroom Flat at Abeokuta</h6>
                    </div> */}
                </div>
                <div className="row">
                    <div className="col-lg-2 col-md-12 col-sm-12 col-xs-12">
                    </div>
                    <div className="col-lg-8 col-md-12 col-sm-12 col-xs-12">
                        <h6 style={{ color: "white" }}>3D Visualization for proposed Foodcity outlet at Igando, Lagos.</h6>
                        <AutoplaySlider animation="openAnimation"
                            play={true}
                            cancelOnInteraction={true} // should stop playing on user interaction
                            interval={3000}
                            bullets={true}
                        >
                            <div data-src="https://res.cloudinary.com/toniedesignsatelier/image/upload/v1595835527/Portfolio/Commercial/Food%20City%20Akowonjo/PRoposed-FoodCity-Restaurant-at-Akowanjo-2_klty2i.jpg" alt='Image 2' />
                            <div data-src="https://res.cloudinary.com/toniedesignsatelier/image/upload/v1596274706/Portfolio/Commercial/Food%20City%20Akowonjo/Untitled_34_pexvx6.jpg" alt="Image 1">
                            </div>
                        </AutoplaySlider>
                    </div>
                    <div className="col-lg-2 col-md-12 col-sm-12- col-xs-12">
                    </div>
                </div>
                <div className="row text-center">

                </div>
            </div>
        )
    }
}
export default Igando;
import React, {Component, useState} from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios'
import { post } from 'jquery';
import firebase from "firebase"
import FileUploader from "react-firebase-file-uploader"

// const API_PATH= 'https://tdamail.herokuapp.com/careers'

const API_PATH= 'https://webhook.site/465912fd-8e78-4601-9658-d1439519d6cd'


class Careers extends Component { 

    state = {
        fname: '',
        email: '',
        role: '',
        phone: '',
        cv: 'Architect / Interior Designer',
        cvSelected: false,
        buttonText: 'Submit',
        mailSent: false,
        isUploading: true
    }
    // handleUploadStart = () => () => this.setState


    fileChangeHandler = (e) => {
        this.setState({
            cv: e.target.files[0]
        })
        console.log(e.target.files[0])
        // console.log("Testc fb vb tttt")     
    }

    handleChange = (param, e) => {
        this.setState({[param]: e.target.value})
    }

    formSubmit =(e) => {
        e.preventDefault()

        // this.fileChangeHandler
        // this.fileChangeHandler()


        this.setState({
            buttonText: 'Sending...'
        })

        
        // let data = {
        //     fname: this.state.name,
        //     email: this.state.email,
        //     role: this.state.role,
        //     phone: this.state.phone,
        //     cv: this.state.cv.files[0]
        // }

        let fd = new FormData();
        fd.append('fname', this.state.fname)
        fd.append('email', this.state.email)
        fd.append('phone', this.state.phone)
        fd.append('role', this.state.role)
        fd.append('cv', this.state.cv)
        // console.log(this.state)
        var extractedData = {
            fname: fd.get('fname'),
            email: fd.get('email'),
            phone: fd.get('phone'),
            role: fd.get('role'),
            cv: fd.get('cv')
        }




        axios({
            method:'post',
            url: API_PATH, 
            data:extractedData,
            headers: {'Content-Type': 'multipart/form-data'}
        })
        .then(res => {
            this.setState({mailSent: true}, this.resetForm())
        })
        .catch( ()=> {
            console.log('Message not sent')
        })

        console.log(extractedData)
    }
     
    resetForm = () => {
        this.setState({
        name: '',
        email: '',
        role: '',
        phone: '',
        cv: '',
        buttonText: 'Submitted',
        mailSent: false
        })
    }

    render() {
        return(
            <div className="container">
                <h1 className="text-center mb-5">Got what it takes to work with us?</h1>
                <form onSubmit={this.formSubmit.bind(this)}>
                    <div>
                    <label htmlFor="fname">Full Name:</label>
                    <input 
                    type="text" 
                    name="fname"
                    id="fname"
                    value={this.state.name}
                    onChange={this.handleChange.bind(this, 'fname')}
                    className="form-control"
                    />
                    </div>
                    <div>
                    <label htmlFor="role">Interested role:</label>
                    
                    {/* <select>
                        {roles.map((role)=> {
                            <option value={role.value} name={role.label} >{role.label}</option>
                        })};
                    </select> */}
                    <select 
                    name="role"
                    value={this.state.role}
                    onChange={this.handleChange.bind(this, 'role')}
                    id="role"
                    className="form-control mb-3">
                    <option value="Architect / Interior Designer"> Architect / Interior Designer</option>
                    <option value="Site Engineer"> Site Engineer</option>
                    <option value="Industrial Training"> Industrial Training</option>
                    <option value="Quantity Surveyor">Quantity Surveyor</option>
                    <option value="NYSC"> NYSC</option>
                    </select>
                    
                    </div>
                    <div>
                    <label htmlFor="email">Email:</label>
                    <input 
                    type="email"
                    id = "email"
                    name="email"
                    value={this.state.email}
                    onChange={this.handleChange.bind(this, 'email')}
                    className="form-control"
                    />
                    </div>
                    <div>
                    <label for ="email">Phone:</label>
                    <input 
                    type="text" 
                    value={this.state.phone}
                    name="phone"
                    id = "email"
                    onChange={this.handleChange.bind(this, 'phone')}
                    className="form-control"
                    />
                    </div>
                    <div>
                    <label htmlFor="cv">Upload CV:</label><br />
                    <input 
                    type="file" 
                    id = "cv"
                    name="cv"
                    onChange={e => this.fileChangeHandler(e)}

                    />
                    </div>
                    <button type="submit" onClick={this.formSubmit} className="mt-4 submit-btn">{this.state.buttonText}</button>

                </form>
            </div>
        )
    }


}

export default Careers;
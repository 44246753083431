import React from 'react';
import ReactDOM from 'react-dom';
import $ from "jquery";
import Media from 'react-media';
import { Link } from 'react-router-dom';
import { BrowserRouter, Route } from 'react-router-dom'

function Services() {
    return (
        <div>
            <div className="container">
                {/* Display this if it's a mobile device */}
                <Media query="(max-width: 767px)" render={() =>
                    (   <div>
                            <div className="row">
                                <div className="col-4 text-center">
                                    <Link to="/Services"><p>Architectural</p></Link>
                                </div>
                                <div className="col-4 text-center">
                                    <Link to="/Construction"><p>Construction</p></Link>
                                </div>
                                <div className="col-4 text-center">
                                    <Link to="/Visualization"><p>3D Designs</p></Link>
                                </div>
                            </div>
                            <div className="container mt-5">
                                <p className='services'>
                                    We are creating a leading architecture practice in Lagos. We believe our essence in design is creating well -designed buildings to enrich peoples experience and improve their quality of life. Along with our clients, we Create buildings that are healthy, comfortable, Flexible, simple and efficient. We deliver them across Nigeria. They span into two different categories: Public Buildings (hospitals, malls, civic centers, educational buildings, recreational buildings), and Residential Buildings (apartments, villas, hotels, motels, etc.)
                                            </p>
                            </div>
                        </div>
                    )}
                />
                <Media query="(min-width: 768px)" render={() =>
                    (
                        <div className="row">
                            {/* <div className="col-4 text-center">
                                <Link to="/Architecture"><h3>Architectural</h3></Link>
                            </div>
                            <div className="col-4 text-center">
                                <Link to="/Construction"><h3>Construction</h3></Link>
                            </div>
                            <div className="col-4 text-center">
                                <Link to="/Visualization"><h3>3D Designs</h3></Link>
                            </div> */}
                            <div className="col-5"style={{verticalAlign:"75%"}} >
                                <h1 style={{fontSize:"75px", verticalAlign:"bottom"}}>Our Services</h1>
                                <div className="col-4 text-center">
                                    <Link to="/Services"><p>Architectural</p></Link>
                                </div>
                                <div>
                                    <Link to="/Construction"><p>Construction</p></Link>
                                </div>
                                <div>
                                    <Link to="/Visualization"><p>3D Designs</p></Link>
                                </div>
                            </div>
                            <div className="col-7">
                                <p className='services'>
                                    We are creating a leading architecture practice in Lagos. We believe our essence in design is creating well -designed buildings to enrich peoples experience and improve their quality of life. Along with our clients, we Create buildings that are healthy, comfortable, Flexible, simple and efficient. We deliver them across Nigeria. They span into two different categories: Public Buildings (hospitals, malls, civic centers, educational buildings, recreational buildings), and Residential Buildings (apartments, villas, hotels, motels, etc.)
                                </p>
                            </div>
                        </div>
                    )}
                />

            </div>
        </div>
    )
}
export default Services;
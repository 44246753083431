import React, {Component} from 'react';
// import Slider from 'react-slick';
// import Slides from '.../components/Slides';
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/custom-animations/open-animation.css';
import 'react-awesome-slider/dist/styles.css';
import withAutoplay from 'react-awesome-slider/dist/autoplay' ;
import { Link } from 'react-router-dom';

const AutoplaySlider = withAutoplay(AwesomeSlider)
class Micros extends Component {
    render () {
        return (
            <div className="container">
                <div className="float-right">
                <Link to="/portfolio"  style={{ color:"red" }}><h4><i className="fa fa-arrow-left" />  Go Back</h4></Link>
                </div>
                <h1 style={{ color:"white" }}>Micros</h1>
                <AutoplaySlider animation="openAnimation"
                    play = {true}
                    cancelOnInteraction={false} // should stop playing on user interaction
                    interval={3000}
                    bullets = {true}
                    >
                    <div data-src="https://res.cloudinary.com/toniedesignsatelier/image/upload/v1595835058/Portfolio/Commercial/Micros%20House%20Of%20Chicken%20And%20Waffles/Micros-House-of-Chicken-and-Waffles-1_yjtgzy.jpg" alt="Image 1">
                        {/* <h1 style={{ zIndex:"3" }}>The answer is -- Don't think about it.</h1> */}
                    </div>
                    <div data-src="https://res.cloudinary.com/toniedesignsatelier/image/upload/v1595835057/Portfolio/Commercial/Micros%20House%20Of%20Chicken%20And%20Waffles/Micros-House-of-Chicken-and-Waffles-2_ljf30y.jpg" alt="Image 2"/>
                </AutoplaySlider>
            </div>
        )
    }
}
export default Micros;
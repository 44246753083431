import React from "react"
import logo from '../assets/images/logo.png';
import slide1 from '../assets/images/slide1.jpg';
import slide2 from '../assets/images/slide2.jpg';
import slide3 from '../assets/images/slide3.jpg'

const Slides = [
    {name: 'Photo 1',
    src: {slide1}
},

    {name: 'Photo 2',
    src: slide2},
    {
        name: 'Photo 2',
        src: slide3
    },
    {
        name: 'Photo 2',
        src: slide3
    },
    {
        name: 'Photo 2',
        src: slide3
    },
    {
        name: 'Photo 2',
        src: slide3
    },
    {
        name: 'Photo 2',
        src: slide3
    },

]
export default Slides;
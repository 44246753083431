import React, { Component } from 'react';
import "react-awesome-lightbox/build/style.css";
import skibi from '../assets/images/slide3.jpg';
import { Link } from 'react-router-dom';
import Media from 'react-media';

class Construction extends Component {
    render() {
        return (
            <div>
                <div className="" style={{ margin: "2%" }}>
                    <div className='row float left pb-4'>
                        <div className='col-6'>
                            <h1 style={{ color: "white" }}>
                                Construction
                        </h1>
                        </div>
                    </div>

                    <Media query="(min-width: 769px)" render={() =>
                        (
                            <>
                                <div className='row text-center pt-2' >
                                    <div className='col-4 text-center'>
                                        <h1 style={{ fontSize: "60px", color: "white" }}>
                                            <i className="fa fa-pencil" />
                                        </h1>
                                        <Link to="/portfolio"><p><button className='btn-custom'>Architectural</button></p></Link>
                                    </div>
                                    <div className='col-4 text-center'>
                                        <h1 style={{ fontSize: "60px", color: "white" }}>
                                            <i className="fa fa-cubes" />
                                        </h1>
                                        <Link to="/visualization"><p><button className='btn-custom' style={{ color: "white" }}>3D-Visuals</button></p></Link>
                                    </div>
                                    <div className='col-4 text-center'>
                                        <h1 style={{ fontSize: "60px", color: "red" }}>
                                            <i className="fa fa-building-o" />
                                        </h1>
                                        <Link to="/construction"><p><button className='btn-custom' style={{ color: "red" }}>Construction</button></p></Link>
                                    </div>
                                </div>
                            </>
                        )}
                    />
                    <Media query="(max-width: 768px)" render={() =>
                        (
                            <>
                                <div className='row text-center pt-2' >
                                    <div className='col-4 text-center'>
                                        <h1 style={{ fontSize: "40px", color: "white" }}>
                                            <i className="fa fa-pencil" />
                                        </h1>
                                        <Link to="/portfolio"><p><button className='btn-custom' style={{ fontSize: "12px",color: "white" }}>Architectural</button></p></Link>
                                    </div>
                                    <div className='col-4 text-center'>
                                        <h1 style={{ fontSize: "40px", color: "white" }}>
                                            <i className="fa fa-cubes" />
                                        </h1>
                                        <Link to="/visualization"><p><button className='btn-custom' style={{ fontSize: "12px",color: "white" }}>3D-Visuals</button></p></Link>
                                    </div>
                                    <div className='col-4 text-center'>
                                        <h1 style={{ fontSize: "40px", color: "red" }}>
                                            <i className="fa fa-building-o" />
                                        </h1>
                                        <Link to="/construction"><p><button className='btn-custom' style={{ fontSize: "12px",color: "red" }}>Construction</button></p></Link>
                                    </div>
                                </div>
                            </>
                        )}
                    />


                </div>
                <div className="row no-gutters" style={{ margin: "0.5%" }}>
                    <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 portfolio-container-2">
                        <Link to="/LekkiConstruction">
                            <img className="portfolio-grayscale" src="https://res.cloudinary.com/toniedesignsatelier/image/upload/v1596146012/Portfolio/Construction%20Lekki/lekki_construction_2_gqd8r2.jpg" alt="Image here" width="100%" height="auto" />
                            <h5 className="portfolio-title">Residential Development</h5>
                            <p className="portfolio-title pt-3 pb-4" style={{ fontWeight: "normal", marginTop: "1px", fontSize: "15px" }}>Oral Estate, Lekki.</p>
                        </Link>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 portfolio-container-2">
                        <Link to="/WarriConstruction">
                            <img className="portfolio-grayscale" src="https://res.cloudinary.com/toniedesignsatelier/image/upload/v1596151927/Portfolio/WarriConstruction/warri_construction_rawj0g.png" alt="Image here" width="100%" height="auto" />
                            <h5 className="portfolio-title">Residential Development</h5>
                            <p className="portfolio-title pt-3 pb-4" style={{ fontWeight: "normal", marginTop: "1px", fontSize: "15px" }}>Sapele, Delta</p>
                        </Link>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 portfolio-container-2">
                        <Link to="/Jakande">
                            <img className="portfolio-grayscale" src='https://res.cloudinary.com/toniedesignsatelier/image/upload/v1596151839/Portfolio/Jakande/jakande_cosntruction_sszfal.jpg' alt="Image loading" width="100%" height="auto" />
                            <h5 className="portfolio-title">Foursquare Gospel Church</h5>
                            <p className="portfolio-title pt-3 pb-4" style={{ fontWeight: "normal", marginTop: "1px", fontSize: "15px" }}>Jakande Lagos.</p>
                        </Link>
                    </div>
                </div>
                <div className="row no-gutters" style={{ margin: "0.5%" }}>
                    <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 portfolio-container-2">
                        <Link to="/LawansonConstruction">
                            <img className="portfolio-grayscale" src='https://res.cloudinary.com/toniedesignsatelier/image/upload/v1596202483/Portfolio/LawansonConstruction/lawanson_construction_1_xr5p0r.jpg' alt="Image loading" width="100%" height="auto" />
                            <h5 className="portfolio-title">Development of Foodcity Outlet</h5>
                            <p className="portfolio-title pt-3 pb-4" style={{ fontWeight: "normal", marginTop: "1px", fontSize: "15px" }}>Lawanson, Lagos.</p>
                        </Link>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 portfolio-container-2">
                        <Link to="/AbeokutaConstruction">
                            <img className="portfolio-grayscale" src='https://res.cloudinary.com/toniedesignsatelier/image/upload/v1596204075/Portfolio/Commercial/Abeokuta/abeokuta_construction_hgjj8o.jpg' alt="Image loading" width="100%" height="auto" />
                            <h5 className="portfolio-title">A Duplex and 4 Bedroom Flat</h5>
                            <p className="portfolio-title pt-3 pb-4" style={{ fontWeight: "normal", marginTop: "1px", fontSize: "15px" }}>Abeokuta</p>
                        </Link>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 portfolio-container-2">
                        <Link to="/OluwoleConstruction">
                            <img className="portfolio-grayscale" src='https://res.cloudinary.com/toniedesignsatelier/image/upload/v1596223111/Portfolio/Commercial/Oluwole/oluwole_3_iqlg8b.jpg' alt="Image loading" width="100%" height="auto" />
                            <h5 className="portfolio-title">Construction of a 3 bedroom flat</h5>
                            <p className="portfolio-title pt-3 pb-4" style={{ fontWeight: "normal", marginTop: "1px", fontSize: "15px" }}>Oluyole, Ibadan</p>
                        </Link>
                    </div>
                </div>
                <div className="row no-gutters" style={{ margin: "0.5%" }}>
                    <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 portfolio-container-2">
                        <Link to="/Egbeda">
                            <img className="portfolio-grayscale" src='https://res.cloudinary.com/toniedesignsatelier/image/upload/v1648068632/Portfolio/Food%20City%20Egbeda/4_htlgr0.jpg' alt="Image loading" width="100%" height="auto" />
                            <h5 className="portfolio-title">Food City Outlet Egbeda, Lagos.</h5>
                            <p className="portfolio-title pt-3 pb-4" style={{ fontWeight: "normal", marginTop: "1px", fontSize: "15px" }}>Egbeda, Lagos.</p>
                        </Link>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 portfolio-container-2">
                        <Link to="/Bugan">
                            <img className="portfolio-grayscale" src='https://res.cloudinary.com/toniedesignsatelier/image/upload/v1648071883/Portfolio/Bugan/3_uy3c2n.jpg' alt="Image loading" width="100%" height="auto" />
                            <h5 className="portfolio-title">Development of an outlet for Bugan Bistro</h5>
                            <p className="portfolio-title pt-3 pb-4" style={{ fontWeight: "normal", marginTop: "1px", fontSize: "15px" }}>Lagos</p>
                        </Link>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 portfolio-container-2">
                        <Link to="/Cele">
                            <img className="portfolio-grayscale" src='https://res.cloudinary.com/toniedesignsatelier/image/upload/v1648070823/Portfolio/Cele%20Elegbe/4_jnq7a7.jpg' alt="Image loading" width="100%" height="auto" />
                            <h5 className="portfolio-title">Foodcity ooutlet at Cele Elegbe.</h5>
                            <p className="portfolio-title pt-3 pb-4" style={{ fontWeight: "normal", marginTop: "1px", fontSize: "15px" }}>Cele, Elegbe</p>
                        </Link>
                    </div>
                </div>
                <div className="row no-gutters" style={{ margin: "0.5%" }}>
                    <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 portfolio-container-2">
                        <Link to="/Akran">
                            <img className="portfolio-grayscale" src='https://res.cloudinary.com/toniedesignsatelier/image/upload/v1648069882/Portfolio/Food%20City%20Oba%20Akran/3_utzztm.jpg' alt="Image loading" width="100%" height="auto" />
                            <h5 className="portfolio-title">Foodcity outlet Oba Akran Ikeja, Lagos.</h5>
                            <p className="portfolio-title pt-3 pb-4" style={{ fontWeight: "normal", marginTop: "1px", fontSize: "15px" }}>Ikeja, Lagos.</p>
                        </Link>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 portfolio-container-2">
                        <Link to="/PrivateRenovation">
                            <img className="portfolio-grayscale" src='https://res.cloudinary.com/toniedesignsatelier/image/upload/v1648073477/Portfolio/Renovation%20/1_tmtu9r.jpg' alt="Image loading" width="100%" height="auto" />
                            <h5 className="portfolio-title">Renovation of a Private Residence at Maryland Lagos.</h5>
                            <p className="portfolio-title pt-3 pb-4" style={{ fontWeight: "normal", marginTop: "1px", fontSize: "15px" }}>Maryland, Lagos.</p>
                        </Link>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 portfolio-container-2">
                        <Link to="/Booth">
                            <img className="portfolio-grayscale" src='https://res.cloudinary.com/toniedesignsatelier/image/upload/v1648072692/Portfolio/Security%20Booth/2_g5xkvp.jpg' alt="Image loading" width="100%" height="auto" />
                            <h5 className="portfolio-title">Construction of a security booth.</h5>
                            <p className="portfolio-title pt-3 pb-4" style={{ fontWeight: "normal", marginTop: "1px", fontSize: "15px" }}>Lagos, Nigeria.</p>
                        </Link>
                    </div>
                </div>
            </div>
            // </div >
        )
    }
}

export default Construction;
import React from 'react';
import ReactDOM from 'react-dom';
import { useMediaQuery } from 'react-responsive';
import MediaQuery from 'react-responsive';
import Media from 'react-media';
import aboutImage from '../assets/images/about.jpg';
import Particles from 'react-particles-js';
import titilayo from '../assets/images/titilayo.jpg';
import adekoya from '../assets/images/adekoya.jpg';
import subomi from '../assets/images/subomi.jpg';
import sanni from '../assets/images/sanni.jpg';
import tonie from '../assets/images/tonie.jpg';
import adebayo from '../assets/images/adebayo.jpg';
import emmanuel from '../assets/images/emmanuel.jpg';
import solomon from '../assets/images/solomon.jpg';
import tamara from '../assets/images/tamara.jpg';
import data from '../components/data/interiorData';
import shafa from '../assets/images/clients/shafa.jpg';
import foodcity from '../assets/images/clients/foodcity.jpg';
import foursquare from '../assets/images/clients/foursquare.jpg';
import haitheb from '../assets/images/clients/Haitheb.jpg';
import '../App.css'

let showTony = () => {
    document.getElementById('tonie').style.width = "100%";
}
let closeTony = () => {
    document.getElementById('tonie').style.width = "0%";
}
let showSubomi = () => {
    document.getElementById('subomi').style.width = "100%";
}
let closeSubomi = () => {
    document.getElementById('subomi').style.width = "0%";
}
let showAdekoya = () => {
    document.getElementById('adekoya').style.width = "100%";
}
let closeAdekoya = () => {
    document.getElementById('adekoya').style.width = "0%";
}
let showSanni = () => {
    document.getElementById('sanni').style.width = "100%";
}
let closeSanni = () => {
    document.getElementById('sanni').style.width = "0%";
}
let showTiti = () => {
    document.getElementById('titi').style.width = "100%";
}
let closeTiti = () => {
    document.getElementById('titi').style.width = "0%";
}
let showBayo = () => {
    document.getElementById('bayo').style.width = "100%";
}
let closeBayo = () => {
    document.getElementById('bayo').style.width = "0%";
}
let showEmmanuel =() => {
    document.getElementById('emmanuel').style.width = "100%";
}
let closeEmmanuel = () => {
    document.getElementById('emmanuel').style.width = "0%";
}
let showSolomon =() => {
    document.getElementById('solomon').style.width = "100%";
}
let closeSolomon = () => {
    document.getElementById('solomon').style.width = "0%";
}
let showTamara =() => {
    document.getElementById('tamara').style.width = "100%";
}
let closeTamara = () => {
    document.getElementById('tamara').style.width = "0%";
}


function About() {
    return (
        <div>
            <Media query="(min-width: 769px)" render={() =>
                (<>
                    <div className="container">
                        <div className='row'>
                            <div className="col-12 text-center pb-3" >
                                <h1 style={{ fontSize: "65px", color: "whitesmoke" }}>About Us</h1>
                                <hr className="horizontal-bar" />
                                <h5 style={{ color:'red' }}>Who we are, what we do.</h5>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6" style={{ textAlign: "justify", wordWrapProperty: "break-word" }}>
                                <p style={{ fontSize: "17px" }}>
                                    Moving with the high rate of infrastructure development in Nigeria, (ToniE Designs Atelier) is a progressive architectural practice firm, providing high quality Architectural services/ Construction/ 3D visualization for both the domestic and commercial sectors. Which will be to a very high standard at low cost. The Idea to build something that lasts, to enhance the quality of urban environment and people’s lives. This Idea is become a reality thanks to the Team at ToniE Designs Atelier, a Practice made of people sharing the passion for design and for the attention to the detail. The important objectives have been reached, solid foundations have been laid to achieve even more ambitious results.
                                    </p>
                            </div>
                            <div className="col-6">
                                <img src='https://res.cloudinary.com/toniedesignsatelier/image/upload/v1596052349/about_new_nuytnt.jpg' alt="Pic" width="100%" height="auto" />
                            </div>
                        </div>
                    </div>
                    <div className="row" style={{ margin: "3%" }}>
                        <div className="col-8 text-right">

                        </div>
                        <div className="col-12 text-center">
                            <h1 className="text-center" style={{ marginTop:"10%", fontSize: "80px", color: "whitesmoke" }}>Vision</h1>
                            <hr className="horizontal-bar" />
                            <h5 style={{ color:'red' }}>See through our lens</h5>
                        </div>
                        <div className="col-12" style={{wordWrapProperty: "break-word" }}>
                            <p style={{ fontSize: "17px", textAlign:'center' }}>
                                To create a centre of excellence integrating available resources required for the edifices to function effectively at a very low cost with cognizance on quality and positive impacts of the environment the project will be situated.
                                </p>
                        </div>
                    </div>

                    {/* ================================================================ Services==================================================================== */}
                    <div className='container'style={{ marginTop: "10%" }}>
                        <div className="row" style={{ marginBottom: "2%"}}>
                            <div className="col-12 text-center pb-3">
                                <h1 style={{ fontSize: "80px", color: "whitesmoke" }}>Services</h1>
                                <hr className="horizontal-bar" />
                                <h5>What we're expert at.</h5>
                            </div>
                            <div className="col-6">
                                <h2 style={{ color: "red" }}>
                                    Architecture
                                    </h2>
                                <p style={{ fontSize: "17px" }}>
                                    We are creating a leading architecture practice in Lagos. We believe our essence in design is creating well -designed buildings to enrich peoples experience and improve their quality of life. Along with our clients, we Create buildings that are healthy, comfortable, flexible, simple and efficient. We deliver them across Nigeria. They span into two different categories: Public Buildings (hospitals, malls, civic centers, educational buildings, recreational buildings), and Residential Buildings (apartments, villas, hotels, motels, etc.)
                                    </p>
                            </div>
                            <div className="col-6">
                                <img src='https://res.cloudinary.com/toniedesignsatelier/image/upload/v1595899851/Portfolio/Commercial/Abeokuta/Private-Residential-Apartment-in-Abeokuta1_st5bp1.jpg' alt="services" width="100%" height="auto" />
                            </div>
                        </div>
                        <div className="row" style={{ marginBottom: "2%" }}>
                            <div className="col-6">
                                <img src='https://res.cloudinary.com/toniedesignsatelier/image/upload/v1595899854/Portfolio/Commercial/Abeokuta/Private-Residential-Apartment-in-Abeokuta-2_s0t0i1.jpg' alt="services" width="100%" height="auto" />
                            </div>
                            <div className="col-6">
                                <h2 style={{ color: "red" }}>
                                    Construction
                                    </h2>
                                <p style={{ fontSize: "17px" }}>
                                    During the construction period, we make ourselves available to answer any questions and address any concerns matters. We are involved during the construction to represent the owner and to conduct site observation visits, to monitor progress, to prepare any required change orders, to review workshop drawings and pay applications.
                                    </p>
                            </div>
                        </div>
                        <div className="row" style={{ marginBottom: "2%" }}>
                            <div className="col-6">
                                <h2 style={{ color: "red" }}>
                                    3D Visualization
                                    </h2>
                                <p style={{ fontSize: "17px" }}>
                                    We specialize in several photorealistic visualizations, our work is characterized by the highest realistic qualities. We offer our client the ability to create photo-realistic simulations that best-demonstrate the product, providing them with a virtuallivable experience of the designed spaces from different views and angles.<br />
                                    <i className="fa fa-arrow-right" style={{ color: "red" }} /> 3D Animation and Rendering<br />
                                    <i className="fa fa-arrow-right" style={{ color: "red" }} /> Model Making<br />
                                    <i className="fa fa-arrow-right" style={{ color: "red" }} /> Post Production Visualization.
                                </p>
                            </div>
                            <div className="col-6">
                                <img src='https://res.cloudinary.com/toniedesignsatelier/image/upload/v1595905108/Portfolio/Commercial/Interior/interiors-17_xlxt5f.jpg' alt="services" width="100%" height="auto" />
                            </div>
                        </div>
                    </div>
                    
                    {/* =======================================================================================Our Processes====================================================================================== */}
                    <div className="container "style={{ marginTop: "8%" }}>
                    <hr style={{width:"100%", height:"30px", color:'red' }}></hr>
                        <div className="row" style={{ marginTop: "1%" }}>
                            <div className="col-12 text-center">
                                <h1 className="text-center" style={{ fontSize: "60px", color: "whitesmoke" }}>Process</h1>
                                <hr className="horizontal-bar"/>
                                <h5>How we do what we do.</h5>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <div>
                                    <h1 className="text-center" style={{ fontSize: "60px", color: "red" }}>
                                        <i className="fa fa-assistive-listening-systems" />
                                    </h1>
                                    <h4 style={{ color: "red" }}>
                                        We Listen
                                    </h4>
                                    <p>
                                        We listen to our clients and their needs for expertise. We pay close attention to what’s right and relevant.
                                            </p>
                                </div>
                                <div>
                                    <h1 className="text-center" style={{ fontSize: "60px", color: "red" }}>
                                        <i className="fa fa-users" />
                                    </h1>
                                    <h4 style={{ color: "red" }}>
                                        We Empathize
                                    </h4>
                                    <p>
                                        We always put our clients at the center of our strategy so our work can create real benefits for them.
                                            </p>
                                </div>
                            </div>
                            <div className="col-6">
                                <div>
                                    <h1 className="text-center" style={{ fontSize: "60px", color: "red" }}>
                                        <i className="fa fa-spinner" />
                                    </h1>
                                    <h4 style={{ color: "red" }}>
                                        We Anticpate
                                    </h4>
                                    <p>
                                        We think not only about what clients say they want, but also how we can anticipate their needs and make the architectural design better.
                                            </p>
                                </div>
                                <div>
                                    <h1 className="text-center" style={{ fontSize: "60px", color: "red" }}>
                                        <i className="fa fa-users" />
                                    </h1>
                                    <h4 style={{ color: "red" }}>
                                        We Collaborate
                                    </h4>
                                    <p>
                                        Great ideas can come from anywhere, so we and our engineering and construction partners work together as one team.
                                            </p>
                                </div>
                            </div>
                            {/* ====================================End Of Our Processes===================================================================== */}
                        </div>
                    </div>

                    <div className="container "style={{ marginTop: "8%" }}>
                    <hr style={{width:"100%", height:"30px", color:'red' }}></hr>
                        <div className="row" style={{ marginTop: "1%" }}>
                            <div className="col-12 text-center">
                                <h1 className="text-center" style={{ fontSize: "60px", color: "whitesmoke" }}>Our Clients</h1>
                                <hr className="horizontal-bar"/>
                                <h5>Amazing clients make the world go round.</h5>
                            </div>
                        </div>
                        <div className="row" style={{justifyContent:'center'}}>
                            <div className="col-4 client-container">
                                <img src={shafa} alt="shafa logo" className="client-image"/>
                            </div>
                            <div className="col-4 client-container">
                                <img src={foodcity} alt="foodcity logo" className="client-image"/>
                            </div>
                            <div className="col-4 client-container">
                                <img src={foursquare} alt="foursquare logo" className="client-image"/>
                            </div>
                            <div className="col-4 client-container">
                                <img src={haitheb} alt="haitheb logo" className="client-image"/>
                            </div>
                            
                            {/* ====================================End Of Our Clients===================================================================== */}
                        </div>
                    </div>

                    {/* ================================================================================Our Team Section============================================================================== */}
                    <div id="tonie" className="new-navbar-overlay">
                        <div className="new-navbar-overlay-content">
                            <img className="rounded-staff-images" src={tonie} alt="tonie" width="250px" />
                            <h4 style={{ color: "whitesmoke" }}>
                                Tonibor Egbebra
                                </h4>
                            <h5 style={{ color: "red" }}>
                                Managing Partner<br />/Lead Architect
                                </h5>
                            <p style={{ color: 'whitesmoke', fontSize: '18px', margin: "1%" }}>
                            Tonibor Egbebra Taiwo, is a graduate of Architecture from Yaba college of Technology. He also bagged a first degree and Masters in Architecture from Bells University of Technology, Ota, Nigeria.  He is a passionate Architect that lives and breathes standard designed Environments. He has led and supervised the design of a wide range of corporate, civic, and Institutional projects that helped significantly redefine and enhance the look of many urban territories today.
                                </p>
                            <a style={{ cursor: "pointer", fontSize: "80px", color: "red" }} onClick={closeTony}>&times;</a>
                        </div>
                    </div>
                    <div className="new-navbar-overlay" id="subomi">
                        <div className="new-navbar-overlay-content">
                            <img className="rounded-staff-images" src={adekoya} alt="subomi" width="250px" />
                            <h4 style={{ color: "whitesmoke" }}>
                                Adekoya Subomi
                                </h4>
                            <h5 style={{ color: "red" }}>
                                Quantity Surveyor
                                </h5>
                            <p style={{ color: 'whitesmoke', fontSize: '18px', margin: "1%" }}>
                                He is a registered member of Nigerian Institute of Quantity Surveyors. He graduated from the department of Quantity surveying Yaba college of technology and also acquired his first degree in Quantity surveying from Bells university of Technology, Ota. He’s also a PRINCE2 Certified project manager. His core competencies include but not limited to Value Engineering Management, Procurement Management, Cost Control and Cost planning, Construction claims recovery etc.
                                </p>
                            <a style={{ cursor: "pointer", fontSize: "80px", color: "red" }} onClick={closeSubomi}>&times;</a>
                        </div>
                    </div>
                    <div className="new-navbar-overlay" id="emmanuel">
                        <div className="new-navbar-overlay-content">
                            <img className="rounded-staff-images" src={emmanuel} alt="subomi" width="250px" />
                            <h4 style={{ color: "whitesmoke" }}>
                                Emmanuel Chinedu
                                </h4>
                            <h5 style={{ color: "red" }}>
                                Interior Project Architect
                                </h5>
                            <p style={{ color: 'whitesmoke', fontSize: '18px', margin: "1%" }}>
                            Emmanuel Chinedu is a seasoned Project Interior Architect, He Graduated from the prestigious Yaba College of Technology Yaba, Lagos, Nigeria, obtained his BSc Degree in Architecture from Caleb University, Imota, Lagos Nigeria. having worked with Univeral Furniture Limited for years and has handled project with Total Nigeria, Guinness Nigeria, Ruff and Tumble, Eko Hotel and suites Lagos, FCMB etc. His portfolio cuts across numerous property solutions.
                                </p>
                            <a style={{ cursor: "pointer", fontSize: "80px", color: "red" }} onClick={closeEmmanuel}>&times;</a>
                        </div>
                    </div>
                    <div className="new-navbar-overlay" id="solomon">
                        <div className="new-navbar-overlay-content">
                            <img className="rounded-staff-images" src={solomon} alt="subomi" width="250px" />
                            <h4 style={{ color: "whitesmoke" }}>
                                Adejumo Solomon
                                </h4>
                            <h5 style={{ color: "red" }}>
                                Site Engineer
                                </h5>
                            <p style={{ color: 'whitesmoke', fontSize: '18px', margin: "1%" }}>
                            Adejumo Solomon Bolatito is a Holder of MSc. and BSc. in civil Engineering from University of Ibadan. He is a member of the Nigeria Society of Engineers (NSE) and a certify Coren Engineer. He is a dynamic engineer who is well vices in structural design, construction and project management whom has execute wide ranged eyed view projects.
                                </p>
                            <a style={{ cursor: "pointer", fontSize: "80px", color: "red" }} onClick={closeSolomon}>&times;</a>
                        </div>
                    </div>
                    <div className="new-navbar-overlay" id="tamara">
                        <div className="new-navbar-overlay-content">
                            <img className="rounded-staff-images" src={tamara} alt="subomi" width="250px" />
                            <h4 style={{ color: "whitesmoke" }}>
                                Adedapo Tamara
                                </h4>
                            <h5 style={{ color: "red" }}>
                                Virtual Assistant
                                </h5>
                            <p style={{ color: 'whitesmoke', fontSize: '18px', margin: "1%" }}>
                            Adedapo Tamara Margaret  is an Accounting graduate of the Yaba College of Technology. She  is also a Bachelor's degree graduate from the department of Psychology at the University of Ibadan.

                            With over 3 years of  experience, she has gained her ground as an executive assistant managing the communication between executives and employee's and adminstrative duties in the company.

                            Her experience has also exposed her to the field of research as well as documention, report and presentation development.
                                </p>
                            <a style={{ cursor: "pointer", fontSize: "80px", color: "red" }} onClick={closeTamara}>&times;</a>
                        </div>
                    </div>
                    <div className="new-navbar-overlay" id="bayo">
                        <div className="new-navbar-overlay-content">
                            <img className="rounded-staff-images" src={adebayo} alt="adebayo adeyemi" width="250px" />
                            <h4 style={{ color: "whitesmoke" }}>
                                Adeyemi Adebayo
                                </h4>
                            <h5 style={{ color: "red" }}>
                                Structural engineer
                                </h5>
                            <p style={{ color: 'whitesmoke', fontSize: '18px', margin: "1%" }}>
                                Adeyemi, Adebayo Samuel is a registered member of Nigerian Society of Engineers (NSE). He graduated from Civil Engineering Department from Federal University of Technology, Akure (FUTA). He has diverse experience of architectural/structural project design, project management, production of design presentation and construction documents for Structural projects. He is also vast at planning to execution of projects.
                                </p>
                            <a style={{ cursor: "pointer", fontSize: "80px", color: "red" }} onClick={closeBayo}>&times;</a>
                        </div>
                    </div>
                    <div className="new-navbar-overlay" id="adekoya">
                        <div className="new-navbar-overlay-content">
                            <img className="rounded-staff-images" src={subomi} alt="Adebayo" width="250px" />
                            <h4 style={{ color: "whitesmoke" }}>
                                Adekola Adebayo
                                </h4>
                            <h5 style={{ color: "red" }}>
                                Architectural Designer
                                </h5>
                            <p style={{ color: 'whitesmoke', fontSize: '18px', margin: "1%" }}>
                                Bayo is a seasoned Architect wwho earned his Bachelors and Masters degrees from Bells university Ota. He has a passion for enhancing his knowledge and skills for the all around progress of her architectural obsession.
                                </p>
                            <a style={{ cursor: "pointer", fontSize: "80px", color: "red" }} onClick={closeAdekoya}>&times;</a>
                        </div>
                    </div>
                    <div className="new-navbar-overlay" id="sanni">
                        <div className="new-navbar-overlay-content">
                            <img className="rounded-staff-images" src={sanni} alt="Sanni" width="250px" />
                            <h4 style={{ color: "whitesmoke" }}>
                                Oluwatomisin Sanni
                            </h4>
                            <h5 style={{ color: "red" }}>
                                Accountant
                            </h5>
                            <p style={{ color: 'whitesmoke', fontSize: '18px', margin: "1%" }}>
                                Tomisin is a chattered accountant With years of experience in Accounting, She likes working with ToniE Designs Atelier because of the creativity of the architectual firm.
                            </p>
                            <a style={{ cursor: "pointer", fontSize: "80px", color: "red" }} onClick={closeSanni}>&times;</a>
                        </div>
                    </div>
                    <div className="new-navbar-overlay" id="titi">
                        <div className="new-navbar-overlay-content">
                            <img className="rounded-staff-images" src={titilayo} alt="Titilayo" width="250px" />
                            <h4 style={{ color: "whitesmoke" }}>
                                Aderibigbe Titi
                                </h4>
                            <h5 style={{ color: "red" }}>
                                Administrative Assistant
                                </h5>
                            <p style={{ color: 'whitesmoke', fontSize: '18px', margin: "1%" }}>
                                Titilayomi Aderibigbe is the office manager at Tonie Designs Atelier. She is very meticulous, very versatile and with her penchant for thoroughness and paying attention to details which she has used immensely to build a strong client base in the company and with special coordinating skill, she’s been able to oversee all the activities and make plans for the growth of the company.
                                <br />She has a degree in Business Administration.
                                </p>
                            <a style={{ cursor: "pointer", fontSize: "80px", color: "red" }} onClick={closeTiti}>&times;</a>
                        </div>
                    </div>
                    {/* =======================================================================Team Members======================================================================================= */}
                    <div className="row" id="team-area" style={{ marginTop:'10%', margBottom:'5%' }} >
                        <div className="col-12">
                            <h1 className="text-center" style={{ fontSize: "35px", color: "whitesmoke" }}>People make all the difference</h1>
                            <hr className="horizontal-bar"/>
                        </div>
                        <div className="col-12">
                            <div className="row" style={{ marginBottom: "50px" }}>
                                <div className="col-lg-4 col-md-6 text-center">
                                    <img className="rounded-staff-images" src={tonie} alt="tonie" width="250px" />
                                    <h4 style={{ color: "whitesmoke" }}>
                                        Tonibor Egbebra
                                        </h4>
                                    <h5 style={{ color: "red" }}>
                                        Lead Architect
                                        </h5>
                                    <button className="btn-custom" onClick={showTony}>
                                        See Profile
                                        </button>
                                </div>
                                <div className="col-4 text-center">
                                    <img className="rounded-staff-images" src={adekoya} alt="subomi" width="250px" />
                                    <h4 style={{ color: "whitesmoke" }}>
                                        Adekoya Subomi
                                        </h4>
                                    <h5 style={{ color: "red" }}>
                                        Quantity Surveyor
                                        </h5>
                                    <button className="btn-custom" onClick={showSubomi}>
                                        See Profile
                                        </button>
                                </div>
                                <div className="col-4 text-center">
                                    <img className="rounded-staff-images" src={emmanuel} alt="subomi" width="250px" />
                                    <h4 style={{ color: "whitesmoke" }}>
                                        Emmanuel Chinedu
                                        </h4>
                                    <h5 style={{ color: "red" }}>
                                        Interior Project Architect
                                        </h5>
                                    <button className="btn-custom" onClick={showEmmanuel}>
                                        See Profile
                                        </button>
                                </div>
                               
                            </div>
                            <div className='row' style={{marginBottom: '50px'}}>
                            <div className="col-4 text-center">
                                    <img className="rounded-staff-images" src={solomon} alt="subomi" width="250px" />
                                    <h4 style={{ color: "whitesmoke" }}>
                                    Adejumo Solomon
                                        </h4>
                                    <h5 style={{ color: "red" }}>
                                        Site Engineer
                                        </h5>
                                    <button className="btn-custom" onClick={showSolomon}>
                                        See Profile
                                        </button>
                                </div>
                                <div className="col-4 text-center">
                                    <img className="rounded-staff-images" src={tamara} alt="subomi" width="250px" />
                                    <h4 style={{ color: "whitesmoke" }}>
                                    Adedapo Tamara
                                        </h4>
                                    <h5 style={{ color: "red" }}>
                                        Virtual Assistant
                                        </h5>
                                    <button className="btn-custom" onClick={showTamara}>
                                        See Profile
                                        </button>
                                </div>
                                <div className="col-4 text-center">
                                    <img className="rounded-staff-images" src={adebayo} alt="adeyemi adebayo" width="250px" />
                                    <h4 style={{ color: "whitesmoke" }}>
                                        Adeyemi Adebayo
                                        </h4>
                                    <h5 style={{ color: "red" }}>
                                        Structural Engineer
                                        </h5>
                                    <button className="btn-custom" onClick={showBayo}>
                                        See Profile
                                        </button>
                                </div>
                            </div>
                            <div className="row" style={{marginBottom: '50px'}}>
                                <div className="col-lg-4 col-md-6 text-center">
                                    <img className="rounded-staff-images" src={subomi} alt="Adebayo" width="250px" />
                                    <h4 style={{ color: "whitesmoke" }}>
                                        Adekola Adebayo
                                        </h4>
                                    <h5 style={{ color: "red" }}>
                                        Architectural Designer
                                        </h5>
                                    <button className="btn-custom" onClick={showAdekoya}>
                                        See Profile
                                        </button>
                                </div>
                                <div className="col-lg-4 col-md-6 text-center">
                                    <img className="rounded-staff-images" src={sanni} alt="Sanni" width="250px" />
                                    <h4 style={{ color: "whitesmoke" }}>
                                        Oluwatomisin Sanni
                                        </h4>
                                    <h5 style={{ color: "red" }}>
                                        Accountant
                                        </h5>
                                    <button className="btn-custom" onClick={showSanni}>
                                        See Profile
                                        </button>
                                </div>
                                <div className="col-4 text-center">
                                    <img className="rounded-staff-images" src={titilayo} alt="Titilayo" width="250px" />
                                    <h4 style={{ color: "whitesmoke" }}>
                                        Aderibigbe Titi
                                        </h4>
                                    <h5 style={{ color: "red" }}>
                                        Administrative Assistant
                                        </h5>
                                    <button className="btn-custom" onClick={showTiti}>
                                        See Profile
                                        </button>
                                </div>
                            </div>
                            {/* ===============================================================================End of Our TeaM============================================================================= */}
                        </div>
                    </div>
                </>
                )}
            />
            <Media query="(max-width: 768px)" render={() =>
                (
                    <>
                        {/* ===========================================================================About Section==================================================================================== */}
                        <div className="row" style={{ margin: "3%" }}>
                            <div className="col-12" style={{ verticalAlign: "middle" }}>
                                <>
                                    <h1 className="text-center" style={{ color: "whitesmoke", fontSize: "60px", }}>About Us</h1>
                                    <hr className="horizontal-bar" />
                                    <h5 className="text-center">Who we are, what we do.</h5>
                                </>
                            </div>
                            <div className="col-12" style={{ textAlign: "left", wordWrapProperty: "break-word" }}>
                                <p style={{ fontSize: "15px" }}>
                                    Moving with the high rate of infrastructure development in Nigeria, (ToniE Designs Atelier) is a progressive architectural practice firm, providing high quality Architectural services/ Construction/ 3D visualization for both the domestic and commercial sectors. Which will be to a very high standard at low cost. The Idea to build something that lasts, to enhance the quality of urban environment and people’s lives. This Idea is become a reality thanks to the Team at ToniE Designs Atelier, a Practice made of people sharing the passion for design and for the attention to the detail. The important objectives have been reached, solid foundations have been laid to achieve even more ambitious results.
                                </p>
                            </div>
                        </div>
                        {/* ============================================================================Vision===================================================================================== */}
                        <div className="row" style={{ margin: "3%" }}>
                            <div className="col-12" style={{ verticalAlign: "middle" }}>
                                <>
                                    <h1 className="text-center" style={{ color: "whitesmoke", fontSize: "60px" }}>Vision</h1>
                                    <hr className="horizontal-bar" />
                                    <h5 className="text-center">See through our lens</h5>
                                </>
                            </div>
                            <div className="col-12" style={{ textAlign: "left", wordWrapProperty: "break-word" }}>
                                <p style={{ fontSize: "15px" }}>
                                    To create a centre of excellence integrating available resources required for the edifices to function effectively at a very low cost with cognizance on quality and positive impacts of the environment the project will be situated.
                                </p>
                            </div>
                        </div>
                        {/* ================================================================ Services==================================================================== */}
                        <div className='container'>
                            <div className="row" style={{ marginBottom: "2%", marginTop: "7%" }}>
                                <div className="col-12">
                                    <h1 className="text-center" style={{ color: "whitesmoke", fontSize: "60px" }}>Services</h1>
                                    <hr className="horizontal-bar" />
                                    <h5 className="text-center">See what we're expert at</h5>
                                </div>
                                <div className="col-12">
                                    <div class0Name="col-12">
                                        <img src='https://res.cloudinary.com/toniedesignsatelier/image/upload/v1595899851/Portfolio/Commercial/Abeokuta/Private-Residential-Apartment-in-Abeokuta1_st5bp1.jpg' alt="services" width="100%" height="auto" />
                                    </div>
                                    <h2 style={{ color: "red" }}>
                                        Architecture
                                    </h2>
                                    <p style={{ fontSize: "15px" }}>
                                        We are creating a leading architecture practice in Lagos. We believe our essence in design is creating well -designed buildings to enrich peoples experience and improve their quality of life. Along with our clients, we Create buildings that are healthy, comfortable, flexible, simple and efficient. We deliver them across Nigeria. They span into two different categories: Public Buildings (hospitals, malls, civic centers, educational buildings, recreational buildings), and Residential Buildings (apartments, villas, hotels, motels, etc.)
                                    </p>
                                </div>
                            </div>
                            <div className="row" style={{ marginBottom: "2%" }}>
                                <div className="col-12">
                                    <img src='https://res.cloudinary.com/toniedesignsatelier/image/upload/v1595899854/Portfolio/Commercial/Abeokuta/Private-Residential-Apartment-in-Abeokuta-2_s0t0i1.jpg' alt="services" width="100%" height="auto" />
                                </div>
                                <div className="col-12">
                                    <h2 style={{ color: "red" }}>
                                        Construction
                                    </h2>
                                    <p style={{ fontSize: "15px" }}>
                                        During the construction period, we make ourselves available to answer any questions and address any concerns matters. We are involved during the construction to represent the owner and to conduct site observation visits, to monitor progress, to prepare any required change orders, to review workshop drawings and pay applications.
                                    </p>
                                </div>
                            </div>
                            <div className="row" style={{ marginBottom: "2%" }}>
                                <div className="col-12">
                                    <img src='https://res.cloudinary.com/toniedesignsatelier/image/upload/v1595905108/Portfolio/Commercial/Interior/interiors-17_xlxt5f.jpg' alt="services" width="100%" height="auto" />
                                </div>
                                <div className="col-12">
                                    <h2 style={{ color: "red" }}>
                                        3D Visualization
                                    </h2>
                                    <p style={{ fontSize: "15px" }}>
                                        We specialize in several photorealistic visualizations, our work is characterized by the highest realistic qualities. We offer our client the ability to create photo-realistic simulations that best-demonstrate the product, providing them with a virtuallivable experience of the designed spaces from different views and angles<br />
                                        <i className="fa fa-arrow-right" style={{ color: "red" }} /> 3D Animation and Rendering<br />
                                        <i className="fa fa-arrow-right" style={{ color: "red" }} /> Model Making<br />
                                        <i className="fa fa-arrow-right" style={{ color: "red" }} /> Post Production Visualization.
                                    </p>
                                </div>
                            </div>
                        </div>
                        {/* =======================================================================================Our Processes====================================================================================== */}
                        <div className="container "style={{ marginTop: "5%" }}>
                            <div className="row">
                                <div className="col-12">
                                    <h1 className="text-center" style={{ color: "whitesmoke", fontSize: "60px" }}>Process</h1>
                                    <hr className="horizontal-bar" />
                                    <h5 className="text-center">How we do what we do</h5>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <h1 className="text-center" style={{ fontSize: "40px", color: "red" }}>
                                        <i className="fa fa-assistive-listening-systems" />
                                    </h1>
                                    <h4 style={{ color: "red" }}>
                                        We Listen
                                    </h4>
                                    <p>
                                        We listen to our clients and their needs for expertise. We pay close attention to what’s right and
                                        relevant.
                                    </p>
                                </div>
                                <div className='col-12'>
                                    <h1 className="text-center" style={{ fontSize: "40px", color: "red" }}>
                                        <i className="fa fa-users" />
                                    </h1>
                                    <h4 style={{ color: "red" }}>
                                        We Empathize
                                    </h4>
                                    <p>
                                        We always put our clients at the center of our strategy so our work can create real benefits for
                                        them.
                                    </p>
                                </div>
                                <div className="col-12">
                                    <h1 className="text-center" style={{ fontSize: "40px", color: "red" }}>
                                        <i className="fa fa-spinner" />
                                    </h1>
                                    <h4 style={{ color: "red" }}>
                                        We Anticpate
                                    </h4>
                                    <p>
                                        We think not only about what clients say they want, but also how we can anticipate their needs and
                                        make the architectural design better.
                                    </p>
                                </div>
                                <div className='col-12'>
                                    <h1 className="text-center" style={{ fontSize: "40px", color: "red" }}>
                                        <i className="fa fa-users" />
                                    </h1>
                                    <h4 style={{ color: "red" }}>
                                        We Collaborate
                                    </h4>
                                    <p>
                                        Great ideas can come from anywhere, so we and our engineering and construction partners work
                                        together as one team.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="container "style={{ marginTop: "8%" }}>
                    <hr style={{width:"100%", height:"30px", color:'red' }}></hr>
                        <div className="row" style={{ marginTop: "1%" }}>
                            <div className="col-12 text-center">
                                <h1 className="text-center" style={{ fontSize: "60px", color: "whitesmoke" }}>Our Clients</h1>
                                <hr className="horizontal-bar"/>
                                <h5>Amazing clients make the world go round.</h5>
                            </div>
                        </div>
                        <div className="row" style={{justifyContent:'center'}}>
                            <div className="col-12 col-sm-12 client-container">
                                <img src={shafa} alt="shafa logo" className="client-image"/>
                            </div>
                            <div className="col-12 client-container">
                                <img src={foodcity} alt="foodcity logo" className="client-image"/>
                            </div>
                            <div className="col-12 client-container">
                                <img src={foursquare} alt="foursquare logo" className="client-image"/>
                            </div>
                            <div className="col-12 client-container">
                                <img src={haitheb} alt="haitheb logo" className="client-image"/>
                            </div>
                            
                            {/* ====================================End Of Our Clients===================================================================== */}
                        </div>
                    </div>
                        {/* ============================================Team======================================= */}
                        <div id="tonie" className="new-navbar-overlay">
                            {/* <a className="closebtn" style={{ cursor: "pointer" }} onClick={closeTony}>&times;</a> */}
                            <div className="new-navbar-overlay-content">
                                <img className="rounded-staff-images" src={tonie} alt="tonie" width="250px" />
                                <h4 style={{ color: "whitesmoke" }}>
                                    Tonibor Egbebra
                                </h4>
                                <h5 style={{ color: "red" }}>
                                    Managing Partner/<br />Lead Architect
                                </h5>
                                <p style={{ color: 'whitesmoke', fontSize: '15px', margin: "1%" }}>
                                Tonibor Egbebra Taiwo, is a graduate of Architecture from Yaba college of Technology. He also bagged a first degree and Masters in Architecture from Bells University of Technology, Ota, Nigeria.  He is a passionate Architect that lives and breathes standard designed Environments. He has led and supervised the design of a wide range of corporate, civic, and Institutional projects that helped significantly redefine and enhance the look of many urban territories today.
                                </p>
                                <a style={{ cursor: "pointer", fontSize: "80px", color: "red" }} onClick={closeTony}>&times;</a>
                            </div>
                        </div>
                        <div className="new-navbar-overlay" id="subomi">
                            <div className="new-navbar-overlay-content">
                                <img className="rounded-staff-images" src={adekoya} alt="subomi" width="250px" />
                                <h4 style={{ color: "whitesmoke" }}>
                                    Adekoya Subomi
                                </h4>
                                <h5 style={{ color: "red" }}>
                                    Quantity Surveyor
                                </h5>
                                <p style={{ color: 'whitesmoke', fontSize: '15px', margin: "1%" }}>
                                    He is a registered member of Nigerian Institute of Quantity Surveyors. He graduated from the department of
                                    Quantity surveying Yaba college of technology and also acquired his first degree in Quantity surveying from
                                    Bells university of Technology, Ota. He’s also a PRINCE2 Certified project manager. His core competencies
                                    include but not limited to Value Engineering Management, Procurement Management, Cost Control and Cost
                                    planning, Construction claims recovery etc.
                                </p>
                                <a style={{ cursor: "pointer", fontSize: "80px", color: "red" }} onClick={closeSubomi}>&times;</a>
                            </div>
                        </div>
                        <div className="new-navbar-overlay" id="adekoya">
                            <div className="new-navbar-overlay-content">
                                <img className="rounded-staff-images" src={subomi} alt="Adebayo" width="250px" />
                                <h4 style={{ color: "whitesmoke" }}>
                                    Adekola Adebayo
                                </h4>
                                <h5 style={{ color: "red" }}>
                                    Architectural Designer
                                </h5>
                                <p style={{ color: 'whitesmoke', fontSize: '15px', margin: "1%" }}>
                                Bayo is a seasoned Architect wwho earned his Bachelors and Masters degrees from Bells university Ota. He has a passion for enhancing his knowledge and skills for the all around progress of her architectural obsession.
                                </p>
                                <a style={{ cursor: "pointer", fontSize: "80px", color: "red" }} onClick={closeAdekoya}>&times;</a>
                            </div>
                        </div>
                        <div className="new-navbar-overlay" id="sanni">
                            <div className="new-navbar-overlay-content">
                                <img className="rounded-staff-images" src={sanni} alt="Sanni" width="250px" />
                                <h4 style={{ color: "whitesmoke" }}>
                                    Oluwatomisin Sanni
                            </h4>
                                <h5 style={{ color: "red" }}>
                                    Accountant
                            </h5>
                                <p style={{ color: 'whitesmoke', fontSize: '15px', margin: "1%" }}>
                                    Tomisin is a chattered accountant With years of experience in Accounting, She likes working with ToniE Designs
                                    Atelier because of the creativity of the architectual firm.
                                </p>
                                <a style={{ cursor: "pointer", fontSize: "80px", color: "red" }} onClick={closeSanni}>&times;</a>
                            </div>
                        </div>
                        <div className="new-navbar-overlay" id="titi">
                            <div className="new-navbar-overlay-content">
                                <img className="rounded-staff-images" src={titilayo} alt="Titilayo" width="250px" />
                                <h4 style={{ color: "whitesmoke" }}>
                                    Aderibigbe Titi
                                </h4>
                                <h5 style={{ color: "red" }}>
                                    Administrative Assistant
                                </h5>
                                <p style={{ color: 'whitesmoke', fontSize: '15px', margin: "2%" }}>
                                    Titilayomi Aderibigbe is the office manager at Tonie Designs Atelier. She is very meticulous, very versatile
                                    and with her penchant for thoroughness and paying attention to details which she has used immensely to build
                                    a strong client base in the company and with special coordinating skill, she’s been able to oversee all the
                                    activities and make plans for the growth of the company.
                                    <br />She has a degree in Business Administration.
                                </p>
                                <a style={{ cursor: "pointer", fontSize: "80px", color: "red" }} onClick={closeTiti}>&times;</a>
                            </div>
                        </div>
                        <div className="new-navbar-overlay" id="emmanuel">
                            <div className="new-navbar-overlay-content">
                                <img className="rounded-staff-images" src={emmanuel} alt="emmanuel" width="250px" />
                                <h4 style={{ color: "whitesmoke" }}>
                                    Emmanuel Chinedu
                                </h4>
                                <h5 style={{ color: "red" }}>
                                    Interior Project Architect
                                </h5>
                                <p style={{ color: 'whitesmoke', fontSize: '15px', margin: "2%" }}>
                                Emmanuel Chinedu is a seasoned Project Interior Architect, He Graduated from the prestigious Yaba College of Technology Yaba, Lagos, Nigeria, obtained his BSc Degree in Architecture from Caleb University, Imota, Lagos Nigeria. having worked with Univeral Furniture Limited for years and has handled project with Total Nigeria, Guinness Nigeria, Ruff and Tumble, Eko Hotel and suites Lagos, FCMB etc. His portfolio cuts across numerous property solutions.
                                </p>
                                <a style={{ cursor: "pointer", fontSize: "80px", color: "red" }} onClick={closeEmmanuel}>&times;</a>
                            </div>
                        </div>
                        <div className="new-navbar-overlay" id="solomon">
                            <div className="new-navbar-overlay-content">
                                <img className="rounded-staff-images" src={solomon} alt="solomon" width="250px" />
                                <h4 style={{ color: "whitesmoke" }}>
                                    Adejumo Solomon
                                </h4>
                                <h5 style={{ color: "red" }}>
                                    Site Engineer
                                </h5>
                                <p style={{ color: 'whitesmoke', fontSize: '15px', margin: "2%" }}>
                                Adejumo Solomon Bolatito is a Holder of MSc. and BSc. in civil Engineering from University of Ibadan. He is a member of the Nigeria Society of Engineers (NSE) and a certify Coren Engineer. He is a dynamic engineer who is well vices in structural design, construction and project management whom has execute wide ranged eyed view projects.
                                </p>
                                <a style={{ cursor: "pointer", fontSize: "80px", color: "red" }} onClick={closeSolomon}>&times;</a>
                            </div>
                        </div>
                        <div className="new-navbar-overlay" id="tamara">
                            <div className="new-navbar-overlay-content">
                                <img className="rounded-staff-images" src={tamara} alt="solomon" width="250px" />
                                <h4 style={{ color: "whitesmoke" }}>
                                    Adedapo Tamara
                                </h4>
                                <h5 style={{ color: "red" }}>
                                    Virtual Assistant
                                </h5>
                                <p style={{ color: 'whitesmoke', fontSize: '15px', margin: "2%" }}>
                                    Adedapo Tamara Margaret is an Accounting graduate of the Yaba College of Technology. She is also a Bachelor's degree graduate from the department of Psychology at the University of Ibadan. With over 3 years of experience, she has gained her ground as an executive assistant managing the communication between executives and employee's and adminstrative duties in the company. Her experience has also exposed her to the field of research as well as documention, report and presentation development.
                                </p>
                                <a style={{ cursor: "pointer", fontSize: "80px", color: "red" }} onClick={closeTamara}>&times;</a>
                            </div>
                        </div>
                        <div className="new-navbar-overlay" id="bayo">
                            <div className="new-navbar-overlay-content">
                                <img className="rounded-staff-images" src={adebayo} alt="solomon" width="250px" />
                                <h4 style={{ color: "whitesmoke" }}>
                                    Adeyemi Adebayo
                                </h4>
                                <h5 style={{ color: "red" }}>
                                    Structural engineer
                                </h5>
                                <p style={{ color: 'whitesmoke', fontSize: '15px', margin: "2%" }}>
                                Adeyemi, Adebayo Samuel is a registered member of Nigerian Society of Engineers (NSE). He graduated from Civil Engineering Department from Federal University of Technology, Akure (FUTA). He has diverse experience of architectural/structural project design, project management, production of design presentation and construction documents for Structural projects. He is also vast at planning to execution of projects.
                                </p>
                                <a style={{ cursor: "pointer", fontSize: "80px", color: "red" }} onClick={closeBayo}>&times;</a>
                            </div>
                        </div>

                        <div className="container" id="team-area" style={{ marginTop:"10%" }}>
                            <div className="col-12 pb-3">
                                <h5 className="text-center" style={{color: "whitesmoke", marginBottom: "3%" }}>People make all the difference</h5>
                                <hr className="horizontal-bar" />
                            </div>
                            <div className="col-12">
                                <div className="row" style={{ marginBottom: "17px" }}>
                                    <div className="col-12 text-left" style={{ marginBottom: "3%" }}>
                                        <img className="rounded-staff-images" src={tonie} alt="tonie" width="50%" />
                                        <h4 style={{ color: "whitesmoke" }}>
                                            Tonibor Egbebra
                                        </h4>
                                        <h5 style={{ color: "red" }}>
                                            Lead Architect
                                        </h5>
                                        <button className="btn-custom" onClick={showTony}>
                                            See Profile
                                        </button>
                                    </div>
                                </div>
                                <div className="row" style={{ marginBottom: "17px" }}>
                                    <div className="col-12 text-right" style={{ marginBottom: "3%" }}>
                                        <img className="rounded-staff-images" src={adekoya} alt="subomi" width="50%" />
                                        <h4 style={{ color: "whitesmoke" }}>
                                            Adekoya Subomi
                                        </h4>
                                        <h5 style={{ color: "red" }}>
                                            Quantity Surveyor
                                        </h5>
                                        <button className="btn-custom" onClick={showSubomi}>
                                            See Profile
                                        </button>
                                    </div>
                                    <div className="col-12 text-left" style={{ marginBottom: "3%" }}>
                                        <img className="rounded-staff-images" src={emmanuel} alt="Emmanuel Chinedu" width="50%" />
                                        <h4 style={{ color: "whitesmoke" }}>
                                            Emmanuel Chinedu
                                        </h4>
                                        <h5 style={{ color: "red" }}>
                                            Interior Project Architect
                                        </h5>
                                        <button className="btn-custom" onClick={showEmmanuel}>
                                            See Profile
                                        </button>
                                    </div>
                                </div>
                                <div className="row" style={{ marginBottom: "17px" }}>
                                    <div className="col-12 text-right" style={{ marginBottom: "3%" }}>
                                        <img className="rounded-staff-images" src={solomon} alt="subomi" width="50%" />
                                        <h4 style={{ color: "whitesmoke" }}>
                                            Adejumo Solomon
                                        </h4>
                                        <h5 style={{ color: "red" }}>
                                            Site Engineer
                                        </h5>
                                        <button className="btn-custom" onClick={showSolomon}>
                                            See Profile
                                        </button>
                                    </div>
                                    <div className="col-12 text-left" style={{ marginBottom: "3%" }}>
                                        <img className="rounded-staff-images" src={tamara} alt="Adeyemi Adebayo" width="50%" />
                                        <h4 style={{ color: "whitesmoke" }}>
                                            Adedapo Tamara
                                        </h4>
                                        <h5 style={{ color: "red" }}>
                                            Virtual Assistant
                                        </h5>
                                        <button className="btn-custom" onClick={showTamara}>
                                            See Profile
                                        </button>
                                    </div>
                                </div>
                                <div className="row" style={{ marginBottom: "17px" }}>
                                    <div className="col-12 text-right" style={{ marginBottom: "3%" }}>
                                        <img className="rounded-staff-images" src={adebayo} alt="Adeyemi Adebayo" width="50%" />
                                        <h4 style={{ color: "whitesmoke" }}>
                                            Adeyemi Adebayo
                                        </h4>
                                        <h5 style={{ color: "red" }}>
                                            Structural Engineer
                                        </h5>
                                        <button className="btn-custom" onClick={showBayo}>
                                            See Profile
                                        </button>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 text-left" style={{ marginBottom: "3%" }}>
                                        <img className="rounded-staff-images" src={subomi} alt="Adebayo" width="50%" />
                                        <h4 style={{ color: "whitesmoke" }}>
                                            Adekola Adebayo
                                        </h4>
                                        <h5 style={{ color: "red" }}>
                                            Architectural Designer
                                        </h5>
                                        <button className="btn-custom" onClick={showAdekoya}>
                                            See Profile
                                        </button>
                                    </div>
                                    <div className="col-12 text-right" style={{ marginBottom: "3%" }}>
                                        <img className="rounded-staff-images" src={sanni} alt="Sanni" width="50%" />
                                        <h4 style={{ color: "whitesmoke" }}>
                                            Oluwatomisin Sanni
                                        </h4>
                                        <h5 style={{ color: "red" }}>
                                            Accountant
                                        </h5>
                                        <button className="btn-custom" onClick={showSanni}>
                                            See Profile
                                        </button>
                                    </div>
                                    <div className="col-12 text-center" style={{ marginBottom: "3%" }}>
                                        <img className="rounded-staff-images" src={titilayo} alt="Titilayo" width="50%" />
                                        <h4 style={{ color: "whitesmoke" }}>
                                            Aderibigbe Titi
                                        </h4>
                                        <h5 style={{ color: "red" }}>
                                            Administrative Assistant
                                        </h5>
                                        <button className="btn-custom" onClick={showTiti}>
                                            See Profile
                                        </button>
                                    </div>
                                </div>
                                {/* ===============================================================================End of Our
        TeaM============================================================================= */}
                            </div>
                        </div>
                        {/* ======================================Clients================================================== */}                    </>
                )}
            />
        </div>
    )
}

export default About;
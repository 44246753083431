import React, {Component} from 'react';
// import Slider from 'react-slick';
// import Slides from '.../components/Slides';
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/custom-animations/open-animation.css';
import 'react-awesome-slider/dist/styles.css';
import withAutoplay from 'react-awesome-slider/dist/autoplay' ;
import { Link } from 'react-router-dom';

const AutoplaySlider = withAutoplay(AwesomeSlider)
class Owerri extends Component {
    render () {
        return (
            <div className="container" style={{ marginTop:"-3%" }}>
                <div className="row">
                    <div className='col-12'>
                    <Link to="/portfolio"  style={{ color:"red" }} className="float-right"><h4><i className="fa fa-arrow-left" /></h4></Link>
                    </div>
                    {/* <div className='col-12'>
                    <h6 style={{ color:"white" }}>Construction Of A duplex and 4 Bedroom Flat at Abeokuta</h6>
                    </div> */}
                </div>
                <div className="row">
                    <div className="col-lg-2 col-md-12 col-sm-12 col-xs-12">
                    </div>
                    <div className="col-lg-8 col-md-12 col-sm-12 col-xs-12">
                        <h6 style={{ color:"white" }}>3D visualization of 3 Bedroom flat at Owerri.</h6>
                        <AutoplaySlider animation="openAnimation"
                    play = {true}
                    cancelOnInteraction={false} // should stop playing on user interaction
                    interval={3000}
                    bullets = {true}
                    >
                    <div data-src="https://res.cloudinary.com/toniedesignsatelier/image/upload/v1595893002/Portfolio/Commercial/Portharcout/3-bedroom-portharcout-2_zplgda.jpg" alt="Image 1">
                    </div>
                    <div data-src="https://res.cloudinary.com/toniedesignsatelier/image/upload/v1595893002/Portfolio/Commercial/Portharcout/3-bedroom-portharcout-1_m6b3po.jpg" alt="Image 2">
                    </div>
                    <div data-src="https://res.cloudinary.com/toniedesignsatelier/image/upload/v1596215556/Portfolio/Commercial/owerri/intei_Scene_4.effectsResult_1_qhmxhk.jpg" alt="Image 3">
                    </div>
                    <div data-src="https://res.cloudinary.com/toniedesignsatelier/image/upload/v1596215548/Portfolio/Commercial/owerri/intei_Scene_3.effectsResult_1_dcjmkq.jpg" alt="Image 4">
                    <div data-src="https://res.cloudinary.com/toniedesignsatelier/image/upload/v1596215539/Portfolio/Commercial/owerri/intei_Scene_2.effectsResult_1_ybm00m.jpg" alt="Image 5">
                    </div>
                    <div data-src="https://res.cloudinary.com/toniedesignsatelier/image/upload/v1596215513/Portfolio/Commercial/owerri/intei_Scene_1.effectsResult_qnibs5.jpg" alt="Image 6"/>
                    </div>
                </AutoplaySlider>
                    </div>
                    <div className="col-lg-2 col-md-12 col-sm-12- col-xs-12">
                    </div>
                </div>
                
            </div>
        )
    }
}
export default Owerri;
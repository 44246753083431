import React from "react";
import ReactDOM from "react-dom";
import "./style.css";

function Footer() {
    return(
        <footer>
            <div className="container-fluid">
                <div className="text-center">
                    <p style={{ fontSize: "smaller" }}>© ToniE Designs Atelier 2020 Designed by <a href="https://api.whatsapp.com/send?phone=2348173861842">Rovr</a></p>
                </div>
                <div>
                    {/* <span className="float-right">
                        <p style={{fontSize: "smaller"}}>ToniE Designs Atelier 2020</p>
                    </span> */}
                </div>
            </div>
        </footer>
    )
}

export default Footer;
import React, { Component } from 'react';
// import Slider from 'react-slick';
// import Slides from '.../components/Slides';
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/custom-animations/open-animation.css';
import 'react-awesome-slider/dist/styles.css';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import { Link } from 'react-router-dom';

const AutoplaySlider = withAutoplay(AwesomeSlider)
class Bugan extends Component {
    render() {
        return (
            <div className="container" style={{ marginTop:"-3%" }}>
                <div className="row">
                    <div className='col-12'>
                    <Link to="/portfolio"  style={{ color:"red" }} className="float-right"><h4><i className="fa fa-arrow-left" /></h4></Link>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-2 col-md-12 col-sm-12 col-xs-12">
                    </div>
                    <div className="col-lg-8 col-md-12 col-sm-12 col-xs-12">
                        <h6 style={{ color: "white" }}>Bugan Bistro, Lagos.</h6>
                        <AutoplaySlider animation="openAnimation"
                            play={true}
                            cancelOnInteraction={false} // should stop playing on user interaction
                            interval={3000}
                            bullets={true}
                        >
                            <div data-src="https://res.cloudinary.com/toniedesignsatelier/image/upload/v1648071883/Portfolio/Bugan/3_uy3c2n.jpg" alt="Image"></div>
                            <div data-src="https://res.cloudinary.com/toniedesignsatelier/image/upload/v1648071733/Portfolio/Bugan/1_ajgmzf.jpg" alt="Image">
                            </div>
                            <div data-src="https://res.cloudinary.com/toniedesignsatelier/image/upload/v1648071873/Portfolio/Bugan/4_tvdwpm.jpg" alt='Image' />
                            <div data-src="https://res.cloudinary.com/toniedesignsatelier/image/upload/v1648071756/Portfolio/Bugan/2_pjaybk.jpg" alt='Image' />
                        </AutoplaySlider>
                    </div>
                    <div className="col-lg-2 col-md-12 col-sm-12- col-xs-12">
                    </div>
                </div>


            </div>
        )
    }
}
export default Bugan;
import slide1 from '../assets/images/slide1.jpg';
import slide2 from '../assets/images/slide2.jpg';
import slide3 from '../assets/images/slide3.jpg';


const PortfolioData = [
    {name: 'Warri',
    image1: slide1,
    image2: slide2,
    image3: slide3
    
    },

    {
        name: 'Spending',
        image1: slide1,
        image2: slide2,
        image3: slide3

    },

    {
        name: 'Warri',
        image1: slide1,
        image2: slide2,
        image3: slide3

    },
    {
        name: 'Spending',
        image1: slide1,
        image2: slide2,
        image3: slide3

    },
    {
        name: 'Spending',
        image1: slide1,
        image2: slide2,
        image3: slide3

    },
    {
        name: 'Spending',
        image1: slide1,
        image2: slide2,
        image3: slide3

    },
]


export default PortfolioData;
import React, {Component} from 'react';
// import Slider from 'react-slick';
import Slides from '../components/Slides';
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/custom-animations/cube-animation.css';
import 'react-awesome-slider/dist/styles.css';
import withAutoplay from 'react-awesome-slider/dist/autoplay' ;
// import withCaption from 'react-awesome-slider/dist/autoplay';

import '../components/style.css'
// import withCaption from 'react-awesome-slider/dist/captioned';
// // import 'react-awesome-slider/dist/styles.css';
// import 'react-awesome-slider/dist/captioned.css';
// import AwsSliderStyles from 'react-awesome-slider/dist/styles.scss';

// import AwesomeSliderStyles from 'react-awesome-slider/src/styled/cube-animation.scss';
const AutoplaySlider = withAutoplay(AwesomeSlider)
// const CaptionedSlider = withCaption(AwesomeSlider);

class Home extends Component {
    render () {
        return (
            <div>
                <h1>Tonie Designs Atelier </h1>
                <AutoplaySlider animation="cubeAnimation"

                    play = {true}
                    cancelOnInteraction={false} // should stop playing on user interaction
                    interval={3000}
                    bullets = {true}
                    fillParent = {true}
                    >
                    {/* <div data-src="https://res.cloudinary.com/toniedesignsatelier/image/upload/v1610785438/slides/slidejan21_fjefwr.jpg" alt="Home Slide 7"/> */}
                    <div data-src="https://res.cloudinary.com/toniedesignsatelier/image/upload/v1610785314/slides/slidejan21-2_tv5vkk.jpg" alt="Home Slide 4"alt="Home Slide 8"/>
                    <div data-src="https://res.cloudinary.com/toniedesignsatelier/image/upload/v1596046572/slides/home_slide_6_kh5b0w.jpg" alt="Home Slide 1">
                        {/* <h1 style={{ zIndex:"3" }}>The answer is -- Don't think about it.</h1> */}
                    </div>
                    <div data-src="https://res.cloudinary.com/toniedesignsatelier/image/upload/v1596048343/slides/new-slide-1_hk1tpo.jpg" alt='Home Slide 1' />
                    <div data-src="https://res.cloudinary.com/toniedesignsatelier/image/upload/v1596277071/slides/slider_edit_t7kns4.jpg" alt="Home Slide 2"/>
                    <div data-src="https://res.cloudinary.com/toniedesignsatelier/image/upload/v1596277348/slides/Untitled_35_g3mnso.jpg" alt="Home Slide 3"/>
                    <div data-src="https://res.cloudinary.com/toniedesignsatelier/image/upload/v1596051346/slides/new_slide_4_pybnxc.jpg" alt="Home Slide 4"/>
                    <div data-src="https://res.cloudinary.com/toniedesignsatelier/image/upload/v1596172842/slides/slide_6_2_ufrhyj.jpg" alt="Home Slide 5"/>
                    <div data-src="https://res.cloudinary.com/toniedesignsatelier/image/upload/v1596278125/slides/Untitled_36_tk3jfg.jpg" alt="Home Slide 6"/>
                    

                    
                </AutoplaySlider>
            </div>
        )
    }
}
export default Home;
import React, { Component } from 'react';
import Lightbox from "react-awesome-lightbox";
import "react-awesome-lightbox/build/style.css";
import skibi from '../assets/images/slide3.jpg';
import { Link } from 'react-router-dom';
import { SRLWrapper } from "simple-react-lightbox";
import Media from 'react-media';

import PortfolioData from '../components/PortfolioData';
import { Container } from 'reactstrap';
import data from '../components/data/interiorData';


class Visualization extends Component {
    render() {
        return (
            <div>
                <div className="" style={{ margin: "2%" }}>
                    <div className='row float left pb-4'>
                        <div className='col-6'>
                            <h1 style={{ color: "white" }}>
                                3D Visualization
                        </h1>
                        </div>
                    </div>
                    <Media query="(min-width: 769px)" render={() => 
                    (
                        <>
                            <div className='row text-center pt-2' >
                                <div className='col-4 text-center'>
                                    <h1 style={{ fontSize: "60px", color: "white" }}>
                                        <i className="fa fa-pencil" />
                                    </h1>
                                    <Link to="/portfolio"><p><button className='btn-custom'>Architectural</button></p></Link>
                                </div>
                                <div className='col-4 text-center'>
                                    <h1 style={{ fontSize: "60px", color: "red" }}>
                                        <i className="fa fa-cubes" />
                                    </h1>
                                    <Link to="/visualization"><p><button className='btn-custom' style={{ color: "red" }}>3D-Visuals</button></p></Link>
                                </div>
                                <div className='col-4 text-center'>
                                    <h1 style={{ fontSize: "60px", color: "white" }}>
                                        <i className="fa fa-building-o" />
                                    </h1>
                                    <Link to="/construction"><p><button className='btn-custom' style={{ color: "white" }}>Construction</button></p></Link>
                                </div>
                            </div>
                        </>
                        )}
                    />
                    <Media query="(max-width: 768px)" render={() => 
                    (
                        <>
                            <div className='row text-center pt-2' >
                                <div className='col-4 text-center'>
                                    <h1 style={{ fontSize: "40px", color: "white" }}>
                                        <i className="fa fa-pencil" />
                                    </h1>
                                    <Link to="/portfolio"><p ><button className='btn-custom' style={{ fontSize: "12px", color: "white" }}>Architectural</button></p></Link>
                                </div>
                                <div className='col-4 text-center'>
                                    <h1 style={{ fontSize: "40px", color: "red" }}>
                                        <i className="fa fa-cubes" />
                                    </h1>
                                    <Link to="/visualization"><p><button className='btn-custom' style={{ fontSize: "12px", color: "red" }}>3D-Visuals</button></p></Link>
                                </div>
                                <div className='col-4 text-center'>
                                    <h1 style={{ fontSize: "40px", color: "white" }}>
                                        <i className="fa fa-building-o" />
                                    </h1>
                                    <Link to="/construction"><p><button className='btn-custom' style={{ fontSize: "12px", color: "white" }}>Construction</button></p></Link>
                                </div>
                            </div>
                        </>
                        )}
                    />
                </div>
                <SRLWrapper>
                    <div className="row no-gutters" style={{ margin: "0.5%", cursor: "pointer" }}>
                        <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 portfolio-container-2">
                            <img className="portfolio-grayscale" src="https://res.cloudinary.com/toniedesignsatelier/image/upload/v1596144351/Portfolio/Interiors/Coldstone-Saka-Tinubu-6_2_otojg5.jpg" alt="Dominos/Coldstone at Port Harcourt" width="100%" height="auto" />
                            <h6 className="portfolio-title">Dominos/Coldstone</h6>
                            <p className="portfolio-title pt-3" style={{ fontWeight: "normal", marginTop: "1px", fontSize: "14px" }}>Port Harcourt</p>
                            {/* <h6 className="portfolio-location mr-4">Port harcout </h6> */}
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 portfolio-container-2">
                            <img className="portfolio-grayscale" src="https://res.cloudinary.com/toniedesignsatelier/image/upload/v1596144346/Portfolio/Interiors/Coldstone-Saka-Tinubu-6_3_rn2mlm.jpg" alt="Coldstone Stand at Saka Tinubu, Lagos" width="100%" height="auto" />
                            <h5 className="portfolio-title"></h5>
                            <h6 className="portfolio-title">Coldstone Stand</h6>
                            <p className="portfolio-title pt-3" style={{ fontWeight: "normal", marginTop: "1px", fontSize: "14px" }}>Saka Tinubu, Lagos</p>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 portfolio-container-2">
                            <img className="portfolio-grayscale" src="https://res.cloudinary.com/toniedesignsatelier/image/upload/v1596252613/Portfolio/Commercial/Office/Untitled_30_nbbxqt.jpg" alt="Office space at Lekki Lagos" width="100%" height="auto" />
                            <h6 className="portfolio-title">Office Space</h6>
                            <p className="portfolio-title pt-3" style={{ fontWeight: "normal", marginTop: "1px", fontSize: "14px" }}>Lekki, Lagos</p>
                        </div>
                    </div>
                    <div className="row no-gutters" style={{ margin: "0.5%" }}>
                        <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 portfolio-container-2">
                            <img className="portfolio-grayscale" src="https://res.cloudinary.com/toniedesignsatelier/image/upload/v1596144192/Portfolio/Interiors/interior_25_n4ekin.jpg" alt="School Hostel at Owerri" width="100%" height="auto" />
                            <h6 className="portfolio-title">School Hostel</h6>
                            <p className="portfolio-title pt-3" style={{ fontWeight: "normal", marginTop: "1px", fontSize: "14px" }}>Owerri</p>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 portfolio-container-2">
                            <img className="portfolio-grayscale" src="https://res.cloudinary.com/toniedesignsatelier/image/upload/v1596144097/Portfolio/Interiors/Coldstone-Saka-Tinubu-interior_zjvw1p.jpg" alt="Hostel common room at Owerri" width="100%" height="auto" />
                            <h6 className="portfolio-title">Hostel common room</h6>
                            <p className="portfolio-title pt-3" style={{ fontWeight: "normal", marginTop: "1px", fontSize: "14px" }}>Owerri</p>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 portfolio-container-2">
                            <img className="portfolio-grayscale" src="https://res.cloudinary.com/toniedesignsatelier/image/upload/v1596144034/Portfolio/Interiors/interior_23_wxpfvw.jpg" alt="Studio apartment at Ikoyi, Lagos" width="100%" height="auto" />
                            <h6 className="portfolio-title">Studio apartment</h6>
                            <p className="portfolio-title pt-3" style={{ fontWeight: "normal", marginTop: "1px", fontSize: "14px" }}>Ikoyi, Lagos</p>

                        </div>
                    </div>
                    <div className="row no-gutters" style={{ margin: "0.5%" }}>
                        <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 portfolio-container-2">
                            <img className="portfolio-grayscale" src="https://res.cloudinary.com/toniedesignsatelier/image/upload/v1596143916/Portfolio/Interiors/interior_122_u06ked.jpg" alt="Studio apartment at Katampe, Abuja" width="100%" height="auto" />
                            <h6 className="portfolio-title">Studio apartment</h6>
                            <p className="portfolio-title pt-3" style={{ fontWeight: "normal", marginTop: "1px", fontSize: "14px" }}>Katampe, Abuja</p>

                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 portfolio-container-2">
                            <img className="portfolio-grayscale" src="https://res.cloudinary.com/toniedesignsatelier/image/upload/v1596143867/Portfolio/Interiors/Coldstone-Saka-Tinubu-6_1_iu8xpt.jpg" alt="Private Bar at Lekki, Lagos" width="100%" height="auto" />
                            <h6 className="portfolio-title">Private bar</h6>
                            <p className="portfolio-title pt-3" style={{ fontWeight: "normal", marginTop: "1px", fontSize: "14px" }}>Lekki, Lagos</p>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 portfolio-container-2">
                            <img className="portfolio-grayscale" src="https://res.cloudinary.com/toniedesignsatelier/image/upload/v1596143819/Portfolio/Interiors/Coldstone-Saka-Tinubu-6_sr9rka.jpg" alt="Living room at Sangotedo, Lagos" width="100%" height="auto" />
                            <h6 className="portfolio-title">Living room</h6>
                            <p className="portfolio-title pt-3" style={{ fontWeight: "normal", marginTop: "1px", fontSize: "14px" }}>Sangotedo, Lagos</p>
                        </div>
                    </div>
                    <div className="row no-gutters" style={{ margin: "0.5%" }}>
                        <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 portfolio-container-2">
                            <img className="portfolio-grayscale" src="https://res.cloudinary.com/toniedesignsatelier/image/upload/v1596143774/Portfolio/Interiors/interior_21_oavdiv.jpg" alt="Living room at Ajah, Lagos" width="100%" height="auto" />
                            <h6 className="portfolio-title">Living room</h6>
                            <p className="portfolio-title pt-3" style={{ fontWeight: "normal", marginTop: "1px", fontSize: "14px" }}>Ajah, Lagos</p>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 portfolio-container-2">
                            <img className="portfolio-grayscale" src="https://res.cloudinary.com/toniedesignsatelier/image/upload/v1596143742/Portfolio/Interiors/interior_20_djtgbw.jpg" alt="Master bedroom at Gwagalada, Abuja" width="100%" height="auto" />
                            <h6 className="portfolio-title">Master bedroom</h6>
                            <p className="portfolio-title pt-3" style={{ fontWeight: "normal", marginTop: "1px", fontSize: "14px" }}>Gwagalada, Abuja</p>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 portfolio-container-2">
                            <img className="portfolio-grayscale" src="https://res.cloudinary.com/toniedesignsatelier/image/upload/v1596143702/Portfolio/Interiors/interior_19_zoamjp.jpg" alt="Private toilet at Ughelli, Delta" width="100%" height="auto" />
                            <h6 className="portfolio-title">Private toilet</h6>
                            <p className="portfolio-title pt-3" style={{ fontWeight: "normal", marginTop: "1px", fontSize: "14px" }}>Ughelli, Delta</p>
                        </div>
                    </div>
                    <div className="row no-gutters" style={{ margin: "0.5%" }}>
                        <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 portfolio-container-2">
                            <img className="portfolio-grayscale" src="https://res.cloudinary.com/toniedesignsatelier/image/upload/v1596143697/Portfolio/Interiors/interior_19_1_v37mq3.jpg" alt="Kids bedroom at Victoria Island, Lagos" width="100%" height="auto" />
                            <h6 className="portfolio-title">Kids bedroom</h6>
                            <p className="portfolio-title pt-3" style={{ fontWeight: "normal", marginTop: "1px", fontSize: "14px" }}>Victoria Island, Lagos</p>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 portfolio-container-2">
                            <img className="portfolio-grayscale" src="https://res.cloudinary.com/toniedesignsatelier/image/upload/v1596143586/Portfolio/Interiors/interior_18_osvqna.jpg" alt="Dining Area at Ikorodu, Lagos" width="100%" height="auto" />
                            <h6 className="portfolio-title">Dining area</h6>
                            <p className="portfolio-title pt-3" style={{ fontWeight: "normal", marginTop: "1px", fontSize: "14px" }}>Ikorodu, Lagos</p>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 portfolio-container-2">
                            <img className="portfolio-grayscale" src="https://res.cloudinary.com/toniedesignsatelier/image/upload/v1596143537/Portfolio/Interiors/interior_17_sfxel1.jpg" alt="Living room at Ikorodu Lagos" width="100%" height="auto" />
                            <h6 className="portfolio-title">Living room</h6>
                            <p className="portfolio-title pt-3" style={{ fontWeight: "normal", marginTop: "1px", fontSize: "14px" }}>Ikorodu, Lagos</p>
                        </div>
                    </div>
                    <div className="row no-gutters" style={{ margin: "0.5%" }}>
                        <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 portfolio-container-2">
                            <img className="portfolio-grayscale" src="https://res.cloudinary.com/toniedesignsatelier/image/upload/v1596143497/Portfolio/Interiors/interior_16_xnsjpr.jpg" alt="Studio room at Port Harcourt" width="100%" height="auto" />
                            <h6 className="portfolio-title">Studio room</h6>
                            <p className="portfolio-title pt-3" style={{ fontWeight: "normal", marginTop: "1px", fontSize: "14px" }}>Port Harcourt</p>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 portfolio-container-2">
                            <img className="portfolio-grayscale" src="https://res.cloudinary.com/toniedesignsatelier/image/upload/v1596143428/Portfolio/Interiors/interior_15_bs9wrs.jpg" alt="Living room at Gwarinpa, Abuja" width="100%" height="auto" />
                            <h6 className="portfolio-title">Living room</h6>
                            <p className="portfolio-title pt-3" style={{ fontWeight: "normal", marginTop: "1px", fontSize: "14px" }}>Gwarinpa, Abuja</p>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 portfolio-container-2">
                            <img className="portfolio-grayscale" src="https://res.cloudinary.com/toniedesignsatelier/image/upload/v1596196077/Portfolio/Commercial/Denoiser/Untitled_6_iwsnkk.jpg" alt="Block of flats at Maitama, Abuja" width="100%" height="auto" />
                            <h6 className="portfolio-title">Block of flats</h6>
                            <p className="portfolio-title pt-3" style={{ fontWeight: "normal", marginTop: "1px", fontSize: "14px" }}>Maitama, Abuja</p>
                        </div>
                    </div>
                    <div className="row no-gutters" style={{ margin: "0.5%" }}>
                        <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 portfolio-container-2">
                            <img className="portfolio-grayscale" src="https://res.cloudinary.com/toniedesignsatelier/image/upload/v1596143414/Portfolio/Interiors/interior_12_oyirew.jpg" alt="Private kitchen at Ring road, Ibadan" width="100%" height="auto" />
                            <h6 className="portfolio-title">Private kitchen</h6>
                            <p className="portfolio-title pt-3" style={{ fontWeight: "normal", marginTop: "1px", fontSize: "14px" }}>Ring road, Ibadan</p>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 portfolio-container-2">
                            <img className="portfolio-grayscale" src="https://res.cloudinary.com/toniedesignsatelier/image/upload/v1596143206/Portfolio/Interiors/interior_11_ermaxw.jpg" alt="Kids bedroom at Sangotedo, Lagos" width="100%" height="auto" />
                            <h6 className="portfolio-title">Kids bedroom</h6>
                            <p className="portfolio-title pt-3" style={{ fontWeight: "normal", marginTop: "1px", fontSize: "14px" }}>Sangotedo, Lagos</p>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 portfolio-container-2">
                            <img className="portfolio-grayscale" src="https://res.cloudinary.com/toniedesignsatelier/image/upload/v1596143163/Portfolio/Interiors/interior_10_grooge.jpg" alt="Bedroom at Ikorodu, Lagos" width="100%" height="auto" />
                            <h6 className="portfolio-title">Bedroom</h6>
                            <p className="portfolio-title pt-3" style={{ fontWeight: "normal", marginTop: "1px", fontSize: "14px" }}>Ikorodu, Lagos</p>
                        </div>
                    </div>
                    <div className="row no-gutters" style={{ margin: "0.5%" }}>
                        <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 portfolio-container-2">
                            <img className="portfolio-grayscale" src="https://res.cloudinary.com/toniedesignsatelier/image/upload/v1596143090/Portfolio/Interiors/interior_9_lqk7oz.jpg" alt="Bedroom at Oluyole, Ibadan" width="100%" height="auto" />
                            <h6 className="portfolio-title">Bedroom</h6>
                            <p className="portfolio-title pt-3" style={{ fontWeight: "normal", marginTop: "1px", fontSize: "14px" }}>Oluyole, Ibadan</p>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 portfolio-container-2">
                            <img className="portfolio-grayscale" src="https://res.cloudinary.com/toniedesignsatelier/image/upload/v1596142964/Portfolio/Interiors/interior_8_imtinp.jpg" alt="Private Cinema at Sapele, Delta" width="100%" height="auto" />
                            <h6 className="portfolio-title">Private Cinema</h6>
                            <p className="portfolio-title pt-3" style={{ fontWeight: "normal", marginTop: "1px", fontSize: "14px" }}>Sapele, Delta</p>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 portfolio-container-2">
                            <img className="portfolio-grayscale" src="https://res.cloudinary.com/toniedesignsatelier/image/upload/v1596142896/Portfolio/Interiors/interior_7_mnde1k.jpg" alt="Food city lounge at Lawanson, Lagos" width="100%" height="auto" />
                            <h6 className="portfolio-title">Foodcity lounge</h6>
                            <p className="portfolio-title pt-3" style={{ fontWeight: "normal", marginTop: "1px", fontSize: "14px" }}>Lawanson, Lagos</p>
                        </div>
                    </div>
                    <div className="row no-gutters" style={{ margin: "0.5%" }}>
                        <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 portfolio-container-2">
                            <img className="portfolio-grayscale" src="https://res.cloudinary.com/toniedesignsatelier/image/upload/v1596142797/Portfolio/Interiors/interior_6_nl10nj.jpg" alt="Wellocity Pharmacy at Katampe, Abuja" width="100%" height="auto" />
                            <h6 className="portfolio-title">Wellocity Pharmacy</h6>
                            <p className="portfolio-title pt-3" style={{ fontWeight: "normal", marginTop: "1px", fontSize: "14px" }}>Katampe, Abuja</p>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 portfolio-container-2">
                            <img className="portfolio-grayscale" src="https://res.cloudinary.com/toniedesignsatelier/image/upload/v1596142737/Portfolio/Interiors/interior_5_wkvz95.jpg" alt="Private Cinema at Lekki, Lagos" width="100%" height="auto" />
                            <h6 className="portfolio-title">Private Cinema</h6>
                            <p className="portfolio-title pt-3" style={{ fontWeight: "normal", marginTop: "1px", fontSize: "14px" }}>Lekki, Lagos</p>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 portfolio-container-2">
                            <img className="portfolio-grayscale" src="https://res.cloudinary.com/toniedesignsatelier/image/upload/v1596142693/Portfolio/Interiors/interior_4_1_her7rz.jpg" alt="Private Cinema at Wuse 2, Abuja" width="100%" height="auto" />
                            <h6 className="portfolio-title">Private Cinema</h6>
                            <p className="portfolio-title pt-3" style={{ fontWeight: "normal", marginTop: "1px", fontSize: "14px" }}>Wuse 2, Abuja</p>
                        </div>
                    </div>
                    <div className="row no-gutters" style={{ margin: "0.5%" }}>
                        <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 portfolio-container-2">
                            <img className="portfolio-grayscale" src="https://res.cloudinary.com/toniedesignsatelier/image/upload/v1596142608/Portfolio/Interiors/interior_4_jigoyy.jpg" alt="Micos house of chicken and Waffles at Admiralty, Lekki, Lagos" width="100%" height="auto" />
                            <h6 className="portfolio-title">Micos house of chicken and Waffles</h6>
                            <p className="portfolio-title pt-3" style={{ fontWeight: "normal", marginTop: "1px", fontSize: "14px" }}>Admiralty, Lekki Lagos</p>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 portfolio-container-2">
                            <img className="portfolio-grayscale" src="https://res.cloudinary.com/toniedesignsatelier/image/upload/v1596142557/Portfolio/Interiors/interior_3_vp22xk.jpg" alt="Interior, Food city Lounge lawanson" width="100%" height="auto" />
                            {/* <h5 className="portfolio-title">Interior, Food city Lounge lawanson</h5> */}
                            <h6 className="portfolio-title">Food city Lounge</h6>
                            <p className="portfolio-title pt-3" style={{ fontWeight: "normal", marginTop: "1px", fontSize: "14px" }}>Lawanson, Lagos</p>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 portfolio-container-2">
                            <img className="portfolio-grayscale" src="https://res.cloudinary.com/toniedesignsatelier/image/upload/v1596142551/Portfolio/Interiors/interior_2_iikaqq.jpg" alt="Dominos/Coldstone at Saka Tinubu" width="100%" height="auto" />
                            <h6 className="portfolio-title">Dominos/Coldstone</h6>
                            <p className="portfolio-title pt-3" style={{ fontWeight: "normal", marginTop: "1px", fontSize: "14px" }}>Saka Tinubu, Lagos</p>
                        </div>
                    </div>
                    <div className="row no-gutters" style={{ margin: "0.5%" }}>
                        <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 portfolio-container-2">
                            <img className="portfolio-grayscale" src="https://res.cloudinary.com/toniedesignsatelier/image/upload/v1595903237/Portfolio/Semi%20Detached/terrace-2_wriql7.jpg" alt="Semi detached building" width="100%" height="auto" />
                            <h6 className="portfolio-title">Semi detached buildings</h6>
                            <p className="portfolio-title pt-3" style={{ fontWeight: "normal", marginTop: "1px", fontSize: "14px" }}>Lekki, Lagos</p>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 portfolio-container-2">
                            <img className="portfolio-grayscale" src="https://res.cloudinary.com/toniedesignsatelier/image/upload/v1596212294/Portfolio/Interiors/Untitled_13_phax8w.jpg" alt="Gladstone shopping mall at Ojo, Lagos" width="100%" height="auto" />
                            <h6 className="portfolio-title">Gladstone shopping mall</h6>
                            <p className="portfolio-title pt-3" style={{ fontWeight: "normal", marginTop: "1px", fontSize: "14px" }}>Ojo, Lagos</p>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 portfolio-container-2">
                            <img className="portfolio-grayscale" src="https://res.cloudinary.com/toniedesignsatelier/image/upload/v1596212293/Portfolio/Interiors/Untitled_12_zrijym.jpg" alt="Micos house of chicken and Waffles at Admiralty, Lekki, Lagos" width="100%" height="auto" />
                            <h6 className="portfolio-title">Micos house of chicken and Waffles</h6>
                            <p className="portfolio-title pt-3" style={{ fontWeight: "normal", marginTop: "1px", fontSize: "14px" }}>Admiralty, Lekki Lagos</p>
                        </div>
                    </div>
                    <div className="row no-gutters" style={{ margin: "0.5%" }}>
                        <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 portfolio-container-2">
                            <img className="portfolio-grayscale" src="https://res.cloudinary.com/toniedesignsatelier/image/upload/v1596212280/Portfolio/Interiors/Untitled_9_ogqrnl.jpg" alt="Foursquare Gospel Church at Bungalow, Lagos" width="100%" height="auto" />
                            <h6 className="portfolio-title">Foursquare Gospel Church</h6>
                            <p className="portfolio-title pt-3" style={{ fontWeight: "normal", marginTop: "1px", fontSize: "14px" }}>Bungalow, Lagos</p>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 portfolio-container-2">
                            <img className="portfolio-grayscale" src="https://res.cloudinary.com/toniedesignsatelier/image/upload/v1596212275/Portfolio/Interiors/Untitled_10_hpqykx.jpg" width="100%" height="auto" alt="Bridge auto shop at Owerri" />
                            <h6 className="portfolio-title">Bridge auto shop</h6>
                            <p className="portfolio-title pt-3" style={{ fontWeight: "normal", marginTop: "1px", fontSize: "14px" }}>Owerri</p>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 portfolio-container-2">
                            <img className="portfolio-grayscale" src="https://res.cloudinary.com/toniedesignsatelier/image/upload/v1596323318/Portfolio/Interiors/Untitled_48_bza61z.jpg" alt="3 bedroom flat at Shimawa, Ogun" width="100%" height="auto" />
                            <h5 className="portfolio-title"></h5>
                            <h6 className="portfolio-title">3 bedroom flat</h6>
                            <p className="portfolio-title pt-3" style={{ fontWeight: "normal", marginTop: "1px", fontSize: "14px" }}>Shimawa, Ogun</p>
                        </div>
                    </div>
                    <div className="row no-gutters" style={{ margin: "0.5%" }}>
                        <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 portfolio-container-2">
                            <img className="portfolio-grayscale" src="https://res.cloudinary.com/toniedesignsatelier/image/upload/v1596212272/Portfolio/Interiors/Untitled_16_zdmt2q.jpg" alt="Block of flats at Minna, Niger" width="100%" height="auto" />
                            <h6 className="portfolio-title">Block of flats</h6>
                            <p className="portfolio-title pt-3" style={{ fontWeight: "normal", marginTop: "1px", fontSize: "14px" }}>Minna, Niger</p>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 portfolio-container-2">
                            <img className="portfolio-grayscale" src="https://res.cloudinary.com/toniedesignsatelier/image/upload/v1596212264/Portfolio/Interiors/Untitled_11_dktmfo.jpg" width="100%" height="auto" alt="Residential duplex at Lekki, Lagos" />
                            <h6 className="portfolio-title">Residential duplex</h6>
                            <p className="portfolio-title pt-3" style={{ fontWeight: "normal", marginTop: "1px", fontSize: "14px" }}>Lekki, Lagos</p>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 portfolio-container-2">
                            <img className="portfolio-grayscale" src="https://res.cloudinary.com/toniedesignsatelier/image/upload/v1596273665/Portfolio/Commercial/Lounge%20in%20Lagos/Untitled_33_y5kswx.jpg" alt="Foodcity Lounge at Lawanson Lagos" width="100%" height="auto" />
                            <h5 className="portfolio-title"></h5>
                            <h6 className="portfolio-title">Foodcity Lounge</h6>
                            <p className="portfolio-title pt-3" style={{ fontWeight: "normal", marginTop: "1px", fontSize: "14px" }}>Lawanson, Lagos</p>

                        </div>
                    </div>
                    <div className="row no-gutters" style={{ margin: "0.5%" }}>
                        <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 portfolio-container-2">
                            <img className="portfolio-grayscale" src="https://res.cloudinary.com/toniedesignsatelier/image/upload/v1596213628/Portfolio/Interiors/Untitled_17_ssyzq5.jpg" alt="4 bedroom flat at Shimawa, Ogun" width="100%" height="auto" />
                            <h6 className="portfolio-title">4 bedroom flat</h6>
                            <p className="portfolio-title pt-3" style={{ fontWeight: "normal", marginTop: "1px", fontSize: "14px" }}>Shimawa, Ogun</p>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 portfolio-container-2">
                            <img className="portfolio-grayscale" src="https://res.cloudinary.com/toniedesignsatelier/image/upload/v1596321448/Portfolio/Interiors/Fashion_house_s9csit.jpg" width="100%" height="auto" alt="Fashion House at Lekki, Lagos" />
                            <h6 className="portfolio-title">Fashion House</h6>
                            <p className="portfolio-title pt-3" style={{ fontWeight: "normal", marginTop: "1px", fontSize: "14px" }}>Lekki, Lagos</p>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 portfolio-container-2">
                            <img className="portfolio-grayscale" src="https://res.cloudinary.com/toniedesignsatelier/image/upload/v1596213642/Portfolio/Interiors/Untitled_19_xtw3f8.jpg" alt="Terrace building at Ajah Lagos" width="100%" height="auto" />
                            <h6 className="portfolio-title">Terrace building</h6>
                            <p className="portfolio-title pt-3" style={{ fontWeight: "normal", marginTop: "1px", fontSize: "14px" }}>Ajah, Lagos</p>
                        </div>
                    </div>
                    <div className="row no-gutters" style={{ margin: "0.5%" }}>
                        <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 portfolio-container-2">
                            <img className="portfolio-grayscale" src="https://res.cloudinary.com/toniedesignsatelier/image/upload/v1596215318/Portfolio/Interiors/Untitled_20_ivyxyj.jpg" alt="Residential duplex at Port Harcourt" width="100%" height="auto" />
                            <h6 className="portfolio-title">Residential duplex</h6>
                            <p className="portfolio-title pt-3" style={{ fontWeight: "normal", marginTop: "1px", fontSize: "14px" }}>Port Harcourt</p>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 portfolio-container-2">
                            <img className="portfolio-grayscale" src="https://res.cloudinary.com/toniedesignsatelier/image/upload/v1596215874/Portfolio/Commercial/Interior/owerri_hamdcq.jpg" width="100%" height="auto" alt="Private sitting room at Onitsha" />
                            <h6 className="portfolio-title">Private Sitting room</h6>
                            <p className="portfolio-title pt-3" style={{ fontWeight: "normal", marginTop: "1px", fontSize: "14px" }}>Onitsha</p>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 portfolio-container-2">
                            <img className="portfolio-grayscale" src="https://res.cloudinary.com/toniedesignsatelier/image/upload/v1596272390/Portfolio/Commercial/Lounge%20in%20Lagos/Untitled_32_byjm8f.jpg" alt="Shores Restaurant/Bar at Sangotedo, Lagos" width="100%" height="auto" />
                            <h6 className="portfolio-title">Shores Restaurant</h6>
                            <p className="portfolio-title pt-3" style={{ fontWeight: "normal", marginTop: "1px", fontSize: "14px" }}>Sangotedo, Lagos</p>
                        </div>
                    </div>
                    <div className="row no-gutters" style={{ margin: "0.5%" }}>
                        <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 portfolio-container-2">
                            <img className="portfolio-grayscale" src="https://res.cloudinary.com/toniedesignsatelier/image/upload/v1596216262/Portfolio/Commercial/Interior/Untitled_22_xyqlis.jpg" alt="Private kitchen at Ikoyi, Lagos" width="100%" height="auto" />
                            <h6 className="portfolio-title">Private kitchen</h6>
                            <p className="portfolio-title pt-3" style={{ fontWeight: "normal", marginTop: "1px", fontSize: "14px" }}>Ikoyi, Lagos</p>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 portfolio-container-2">
                            <img className="portfolio-grayscale" src="https://res.cloudinary.com/toniedesignsatelier/image/upload/v1596216634/Portfolio/Commercial/Interior/Untitled_23_abgg0u.jpg" width="100%" height="auto" alt="Duplex living room, Kano" />
                            <h6 className="portfolio-title">Duplex living room</h6>
                            <p className="portfolio-title pt-3" style={{ fontWeight: "normal", marginTop: "1px", fontSize: "14px" }}>Zoo road, Kano</p>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 portfolio-container-2">
                            <img className="portfolio-grayscale" src="https://res.cloudinary.com/toniedesignsatelier/image/upload/v1596322626/Portfolio/Interiors/Untitled_47_gaegww.jpg" alt="4 bedroom flat at Omole, Lagos" width="100%" height="auto" />
                            <h6 className="portfolio-title">3 bedroom flat</h6>
                            <p className="portfolio-title pt-3" style={{ fontWeight: "normal", marginTop: "1px", fontSize: "14px" }}>Omole, Lagos</p>
                        </div>
                    </div>


                </SRLWrapper>
            </div>
            // </div >
        )
    }
}

export default Visualization;

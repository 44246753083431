import React from 'react';
import ReactDOM from 'react-dom';
import $ from "jquery";
import Media from 'react-media';

function Contact() {
    return(
        <div>
            <div className="container">
                <div className="row">
                    {/* <div className="col-4">
                        <h1 style={{fontSize:"80px"}}> Contact Us</h1><br />


                    </div>
                    <div className="col-8 float-right">
                        {/* <p className="float-right">Let's Build Something Amazing</p> */}
                        {/* <iframe className="float-right"src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3964.1000184593067!2d3.363128914477252!3d6.509022595291688!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103b8c440db36047%3A0x843a7c2d8e04900f!2s49%20Clegg%20St%2C%20Yaba%2C%20Lagos!5e0!3m2!1sen!2sng!4v1594684323020!5m2!1sen!2sng" width="80%" height="80%" frameBorder="0" style={{border:"0"}} allowFullscreen="" ariaHidden="false" tabIndex="0"></iframe> */}
                        {/* <p>Testing mic</p> */}
                    {/* </div>} */}
                    <div className="col-md-6" style={{backgroundColor:"black"}}>
                        <h1 style={{ fontSize: "80px" }}> Contact Us</h1><br />
                        <i className="fa fa-home ml-2 mb-3" style={content}> 49, Clegg Street, Surulere Lagos. Nigeria.</i><br />
                        <div className="row">
                            <div className="col-xs-6 ml-3 mb-3">
                                <i className="fa fa-phone ml-2" style={content}> +234 705 1372 500</i>
                            </div>
                            <div className="col-xs-6 ml-3">
                                <i className="fa fa-phone" style={content}> +234 703 3751 359</i>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xs-6 ml-3">
                                <i className="fa fa-envelope ml-2 mb-3" style={content}> info@toniedesignsatelier.com</i>
                            </div>
                            <div className="col-xs-6 ml-3">
                                <i className="fa fa-envelope ml-2 mb-4" style={content}> toniedesignsatelier@gmail.com</i>
                            </div>
                        </div>
                            
                        
                        {/* <i className="fa fa-phone ml-2"></i> */}
                    </div>
                    <div className="col-md-6" style={{ backgroundColor: "white" }}>

                    </div>

                </div>
            </div>
        </div>
    )
}
const content= {
    fontSize: '20px'
}
export default Contact;
import React, {Component} from 'react';
// import Slider from 'react-slick';
// import Slides from '.../components/Slides';
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/custom-animations/open-animation.css';
import 'react-awesome-slider/dist/styles.css';
import withAutoplay from 'react-awesome-slider/dist/autoplay' ;
import { Link } from 'react-router-dom';

const AutoplaySlider = withAutoplay(AwesomeSlider)
class Studio extends Component {
    render () {
        return (
            <div className="container">
                <div className="float-right">
                <Link to="/portfolio"  style={{ color:"red" }}><h4><i className="fa fa-arrow-left" />  Go Back</h4></Link>
                </div>
                <h1 style={{ color:"white" }}>3D Interior Visualization of a studio apartment</h1>
                <AutoplaySlider animation="openAnimation"
                    play = {true}
                    cancelOnInteraction={false} // should stop playing on user interaction
                    interval={3000}
                    bullets = {true}
                    >
                    <div data-src="https://res.cloudinary.com/toniedesignsatelier/image/upload/v1595902690/Portfolio/Commercial/Studio%20Apartment/studio-apartment-3_myng8e.jpg" alt="Image 1">
                        {/* <h1 style={{ zIndex:"3" }}>The answer is -- Don't think about it.</h1> */}
                    </div>
                    <div data-src="https://res.cloudinary.com/toniedesignsatelier/image/upload/v1595902687/Portfolio/Commercial/Studio%20Apartment/studio-apartment-2_o4dgxt.jpg" alt="Image 2"/>
                    <div data-src="https://res.cloudinary.com/toniedesignsatelier/image/upload/v1595902686/Portfolio/Commercial/Studio%20Apartment/studio-apartment-1_ct0unc.jpg" alt="Image 3"/>
                </AutoplaySlider>
            </div>
        )
    }
}
export default Studio;
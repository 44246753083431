import React from 'react';
import logo from './logo.svg';
import './App.css';
import { Link } from 'react-router-dom';
import Navbar from "./components/Navbar.js";
import Footer from "./components/Footer.js";
import About from "./pages/About.js";
import Home from "./pages/Home.js"
import Contact from "./pages/Contact";
import Careers from "./pages/Careers"
import './components/style.css';
import Services from './pages/Services';
import newContact from './pages/newContact';
import Portfolio from './pages/Portfolio';
import Particles from 'react-particles-js';
import BridgeAutos from './pages/Portfolio/BridgeAutos';
import SakaTinubu from './pages/Portfolio/SakaTinubu';
import  Igando from './pages/Portfolio/Igando';
import Lawanson from './pages/Portfolio/Lawanson';
import Akran from './pages/Portfolio/Akran';
import Gladstone from './pages/Portfolio/Gladstone';
import Micros from './pages/Portfolio/Micros';
import Cinema from './pages/Portfolio/Cinema';
import Egbeda from './pages/Portfolio/Egbeda';
import Cele from './pages/Portfolio/Cele';
import Shafa from './pages/Portfolio/Shafa';
import Wellocity from './pages/Portfolio/Wellocity';
import WarriHotel from './pages/Portfolio/WarriHotel';
import Bungalow from './pages/Portfolio/Bungalow';
import FGCEjigbo from './pages/Portfolio/FGCEjigbo';
import FGCBungalow from './pages/Portfolio/FGCBungalow';
import Niger from './pages/Portfolio/Niger';
import Denois from './pages/Portfolio/denois';
import Mowe from './pages/Portfolio/Mowe';
import Owerri from './pages/Portfolio/Owerri';
import Elepe from './pages/Portfolio/Elepe';
import DuplexLekki from './pages/Portfolio/DuplexLekki';
import Ikota from './pages/Portfolio/Ikota';
import Omole from './pages/Portfolio/Omole';
import HostelOwerri from './pages/Portfolio/HostelOwerri';
import Abeokuta from './pages/Portfolio/Abeokuta';
import LawansonConstruction from './pages/Portfolio/LawansonConstruction';
import AbeokutaConstruction from './pages/Portfolio/AbeokutaConstruction';
import Sangotedo from './pages/Portfolio/Sangotedo';
import PrivatePortharcout from './pages/Portfolio/PrivatePortharcout';
import Studio from './pages/Portfolio/Studio';
import SemiDetached from './pages/Portfolio/SemiDetached';
import Shimawa from './pages/Portfolio/Shimawa';
import WarriConstruction from './pages/Portfolio/WarriConstruction';
import Oluwole from './pages/Portfolio/Oluwole';
import OluwoleConstruction from './pages/Portfolio/OluwoleConstruction';
import Jakande from './pages/Portfolio/Jakande';
import Detached from './pages/Portfolio/Detached';
import Visualization from './pages/Visualization';
import SimpleReactLightbox from "simple-react-lightbox";
import DominosPH from './pages/Portfolio/DominosPH';
import Construction from './pages/Construction';
// import  from './assets/images/Page404';
import Page404Img from './assets/images/page404.jpg';


// import Home from "./pages/Home.js";
import { BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import LekkiConstruction from './pages/Portfolio/LekkiConstruction';
import Bugan from './pages/Portfolio/Bugan';
import Booth from './pages/Portfolio/Booth';
import PrivateRenovation from './pages/Portfolio/PrivateRenovation';

const Page404 = ({ location }) => (
  <div className="container text-center">
    <h3><Link to="/">Go to home page</Link></h3>
    <img src={Page404Img} className="text-center"/>
  </div>
);

function App() {
  return (
    <Router>
    <div>
      <SimpleReactLightbox>
      {/* <Router> */}
        <Navbar />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/about" component = {About} />
          <Route exact path="/contact" component={Contact} />
          <Route exact path="/Services" component={Services} />
          <Route exact path="/newcontact" component={newContact} />
          <Route exact path = "/Portfolio" component={Portfolio} />
          <Route exact path = "/Careers" component={Careers} />
          {/* Portfolio Pages */}
          <Route exact path = "/bridgeautos" component={BridgeAutos} />
          <Route exact path = "/sakatinubu" component={SakaTinubu} />
          <Route exact path = "/Igando" component={Igando} />
          <Route exact path = "/Lawanson" component={Lawanson} />
          <Route exact path = "/Gladstone" component={Gladstone} />
          <Route exact path = "/Micros" component={Micros} />
          <Route exact path = "/Cinema" component={Cinema} />
          <Route exact path = "/Shafa" component={Shafa} />
          <Route exact path = "/Wellocity" component={Wellocity} />
          <Route exact path = "/WarriHotel" component={WarriHotel} />
          <Route exact path = "/FGCEjigbo" component={FGCEjigbo} />
          <Route exact path = "/FGCBungalow" component={FGCBungalow} />
          <Route exact path = "/Mowe" component={Mowe} />
          <Route exact path = "/Owerri" component={Owerri} />
          <Route exact path = "/Elepe" component={Elepe} />
          <Route exact path = "/DuplexLekki" component={DuplexLekki} />
          <Route exact path = "/Ikota" component={Ikota} />
          <Route exact path = "/Omole" component={Omole} />
          <Route exact path = "/HostelOwerri" component={HostelOwerri} />
          <Route exact path = "/Abeokuta" component={Abeokuta} />
          <Route exact path = "/Sangotedo" component={Sangotedo} />
          <Route exact path = "/Studio" component={Studio} />
          <Route exact path = "/SemiDetached" component={SemiDetached} />
          <Route exact path = "/Detached" component={Detached} />
          {/* <Route exact path = "/Interiors" component={Interiors} /> */}
          <Route exact path = "/PrivatePortharcout" component={PrivatePortharcout} />
          <Route exact path = "/Visualization" component={Visualization} />
          <Route exact path = "/DominosPH" component={DominosPH} />
          <Route exact path = "/Bungalow" component={Bungalow} />
          <Route exact path = "/Construction" component={Construction} />
          <Route exact path = "/WarriConstruction" component={WarriConstruction} />
          <Route exact path = "/Jakande" component={Jakande} />
          <Route exact path = "/LekkiConstruction" component={LekkiConstruction} />
          <Route exact path = "/LawansonConstruction" component={LawansonConstruction} />
          <Route exact path = "/AbeokutaConstruction" component={AbeokutaConstruction} />
          <Route exact path = "/OluwoleConstruction" component={OluwoleConstruction} />
          <Route exact path = "/Oluwole" component={Oluwole} />
          <Route exact path = "/Niger" component={Niger} />
          <Route exact path = "/Denois" component={Denois} />
          <Route exact path = "/Shimawa" component={Shimawa} />
          <Route exact path = "/Egbeda" component={Egbeda} />
          <Route exact path = "/Akran" component={Akran} />
          <Route exact path = "/Cele" component={Cele} />
          <Route exact path = "/Bugan" component={Bugan} />
          <Route exact path = "/Booth" component={Booth} />
          <Route exact path = "/PrivateRenocation" component={PrivateRenovation} />
          <Route component= {Page404} />

          
          
        </Switch>
        <Footer />
        </SimpleReactLightbox>
    </div>
    </Router>
  );
}

export default App;

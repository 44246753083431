import React, { Component } from 'react';
import Lightbox from "react-awesome-lightbox";
import "react-awesome-lightbox/build/style.css";
import skibi from '../assets/images/slide3.jpg';
import { Link } from 'react-router-dom';
import Media from 'react-media';

import PortfolioData from '../components/PortfolioData';
import { Container } from 'reactstrap';

class Portfolio extends Component {
    render() {
        return (
            <div>
                <div className="" style={{ margin: "1%" }}>
                    <div className='row float left'>
                        <div className='col-6'>
                            <h1 style={{ color: "white" }}>
                                Projects
                        </h1>
                        </div>
                    </div>

                    <Media query="(min-width: 769px)" render={() => 
                    (
                        <>
                            <div className='row text-center pt-2' >
                        <div className='col-4 text-center'>
                            <h1 style={{ fontSize: "60px", color: "red" }}>
                                <i className="fa fa-pencil" />
                            </h1>
                            <Link to="/portfolio"><p><button className='btn-custom' style={{ color: "red" }}>Architectural</button></p></Link>
                        </div>
                        <div className='col-4 text-center'>
                            <h1 style={{ fontSize: "60px", color: "white" }}>
                                <i className="fa fa-cubes" />
                            </h1>
                            <Link to="/visualization"><p><button className='btn-custom' style={{ color: "white" }}>3D-Visuals</button></p></Link>
                        </div>
                        <div className='col-4 text-center'>
                            <h1 style={{ fontSize: "60px", color: "white" }}>
                                <i className="fa fa-building-o" />
                            </h1>
                            <Link to="/construction"><p><button className='btn-custom' style={{ color: "white" }}>Construction</button></p></Link>
                        </div>
                    </div>
                        </>
                        )}
                    />
                    <Media query="(max-width: 768px)" render={() => 
                    (
                        <>
                            <div className='row text-center pt-2' >
                        <div className='col-4 text-center'>
                            <h1 style={{ fontSize: "40px", color: "red" }}>
                                <i className="fa fa-pencil" />
                            </h1>
                            <Link to="/portfolio"><p><button className='btn-custom' style={{fontSize: "12px", color: "red" }}>Architectural</button></p></Link>
                        </div>
                        <div className='col-4 text-center'>
                            <h1 style={{ fontSize: "40px", color: "white" }}>
                                <i className="fa fa-cubes" />
                            </h1>
                            <Link to="/visualization"><p><button className='btn-custom' style={{ fontSize: "12px", color: "white" }}>3D-Visuals</button></p></Link>
                        </div>
                        <div className='col-4 text-center'>
                            <h1 style={{ fontSize: "40px", color: "white" }}>
                                <i className="fa fa-building-o" />
                            </h1>
                            <Link to="/construction"><p><button className='btn-custom' style={{ fontSize: "12px", color: "white" }}>Construction</button></p></Link>
                        </div>
                    </div>
                        </>
                        )}
                    />
                    
                    <div className="row" style={{ marginTop: "2%" }}>
                        <div className="col-lg-4 col-md-12 col-sm-12 col-xs-6 portfolio-container">
                            <Link to="/SakaTinubu">
                                <img className="portfolio-grayscale" src='https://res.cloudinary.com/toniedesignsatelier/image/upload/v1595834146/Portfolio/Commercial/Cold%20Stone%20Saka%20Tinubu/Coldstone-Saka-Tinubu-2_ydw079.jpg' alt="Image loading" width="100%" height="auto%" />
                                <h5 className="portfolio-title">Coldstone/Dominos</h5>
                                <p className="portfolio-title pt-3 pb-4" style={{ fontWeight:"normal", marginTop:"1px", fontSize:"15px" }}>Saka Tinubu, Lagos</p>
                            </Link>
                        </div>

                        <div className="col-lg-4 col-md-12  col-sm-12 col-xs-12 portfolio-container">
                            <Link to="/Lawanson">
                                <img className="portfolio-grayscale" src='https://res.cloudinary.com/toniedesignsatelier/image/upload/v1595834429/Portfolio/Commercial/Food%20City%20Lawanson/Proposed-Foodcity-Restaurant-Lawanson-2_l8oyjq.jpg' alt="Image loading" width="100%" height="auto" />
                                <h5 className="portfolio-title">Foodcity</h5>
                                <p className="portfolio-title pt-3 pb-4" style={{ fontWeight:"normal", marginTop:"1px", fontSize:"15px" }}>Lawanson, Lagos</p>
                            </Link>
                        </div>
                        <div className="col-lg-4 col-md-12  col-sm-12 col-xs-12 portfolio-container">
                            <Link to="/Igando">
                                <img className="portfolio-grayscale" src='https://res.cloudinary.com/toniedesignsatelier/image/upload/v1596272110/Portfolio/Commercial/Food%20City%20Akowonjo/Untitled_31_ynibfd.jpg' alt="Image loading" width="100%" height="auto" />
                                <h5 className="portfolio-title">Foodcity</h5>
                                <p className="portfolio-title pt-3 pb-4" style={{ fontWeight:"normal", marginTop:"1px", fontSize:"15px" }}>Igando, Lagos</p>
                            </Link>
                        </div>
                        <div className="col-lg-4 col-md-12  col-sm-12 col-xs-12 portfolio-container-potrait">
                            <Link to="/Gladstone">
                                <img className="portfolio-grayscale" src='https://res.cloudinary.com/toniedesignsatelier/image/upload/v1595841745/Portfolio/Commercial/Landstone%20Mall/Gladstone_Cover_ydngnv.jpg' alt="Image loading" width="100%" height="auto" />
                                <h5 className="portfolio-title">Gladstone Shopping Mall</h5>
                                <p className="portfolio-title pt-3 pb-4" style={{ fontWeight:"normal", marginTop:"1px", fontSize:"15px" }}>Ojo road, Lagos</p>
                            </Link>
                        </div>
                        <div className="col-lg-4 col-md-12  col-sm-12 col-xs-12 portfolio-container" style={{ marginTop: "0.5%" }}>
                            <Link to="/shafa">
                                <img className="portfolio-grayscale" src='https://res.cloudinary.com/toniedesignsatelier/image/upload/v1595836030/Portfolio/Commercial/Shafa%20Filling%20Station/safa-fillin-station-abuja-3_iigfy1.jpg' alt="Image loading" width="100%" height="auto" />
                                <h5 className="portfolio-title">Shafa Gas Station</h5>
                                <p className="portfolio-title pt-3 pb-4" style={{ fontWeight:"normal", marginTop:"1px", fontSize:"15px" }}>Wuye, Abuja</p>
                            </Link>
                        </div>
                        <div className="col-lg-4 col-md-12  col-sm-12 col-xs-12 portfolio-container-potrait">
                            <Link to="/Bridgeautos">
                                <img className="portfolio-grayscale" src='https://res.cloudinary.com/toniedesignsatelier/image/upload/v1595842321/Portfolio/Commercial/Auto%20shop/Auto_Shop_cover_uhm1xw.jpg' alt="Image loading" width="100%" height="auto" />
                                <h5 className="portfolio-title">Bridge Autos </h5>
                                <p className="portfolio-title pt-3 pb-4" style={{ fontWeight:"normal", marginTop:"1px", fontSize:"15px" }}>Onitsha</p>
                            </Link>
                        </div>

                    </div>
                    <div className="row" style={{ marginTop: "0.5%" }}>
                        <div className="col-lg-4 col-md-12  col-sm-12 col-xs-12 portfolio-container">
                            <Link to="/Mowe">
                                <img className="portfolio-grayscale" src='https://res.cloudinary.com/toniedesignsatelier/image/upload/v1596365495/Portfolio/Commercial/Mowe/Untitled_49_rsrmwy.jpg' alt="Image loading" width="100%" height="auto" />
                                <h5 className="portfolio-title">3 bedroom flat</h5>
                                <p className="portfolio-title pt-3 pb-4" style={{ fontWeight:"normal", marginTop:"1px", fontSize:"15px" }}>Mowe, Ogun</p>
                            </Link>
                        </div>
                        <div className="col-lg-4 col-md-12  col-sm-12 col-xs-12 portfolio-container">
                            <Link to="/Ikota">
                                <img className="portfolio-grayscale" src='https://res.cloudinary.com/toniedesignsatelier/image/upload/v1595843984/Portfolio/Commercial/Ikota%20Terrace/Ikota_Terrace_es83io.jpg' alt="Image loading" width="100%" height="auto" />
                                <h5 className="portfolio-title">Terrace Building </h5>
                                <p className="portfolio-title pt-3 pb-4" style={{ fontWeight:"normal", marginTop:"1px", fontSize:"15px" }}>Ikota, Lagos</p>
                            </Link>
                        </div>
                        <div className="col-lg-4 col-md-12  col-sm-12 col-xs-12 portfolio-container">
                            <Link to="/Owerri">
                                <img className="portfolio-grayscale" src='https://res.cloudinary.com/toniedesignsatelier/image/upload/v1595893002/Portfolio/Commercial/Portharcout/3-bedroom-portharcout-2_zplgda.jpg' alt="Image loading" width="100%" height="auto" />
                                <h5 className="portfolio-title">3 bedroom flat</h5>
                                <p className="portfolio-title pt-3 pb-4" style={{ fontWeight:"normal", marginTop:"1px", fontSize:"15px" }}>Owerri</p>
                            </Link>
                        </div>
                        <div className="col-lg-4 col-md-12  col-sm-12 col-xs-12 portfolio-container-potrait">
                            <Link to="/FGCEjigbo">
                                <img className="portfolio-grayscale" src='https://res.cloudinary.com/toniedesignsatelier/image/upload/v1595844600/Portfolio/Commercial/Four%20Square%20Ejigbo/Four_Square_Ejigbo_xubbdk.jpg' alt="Image loading" width="100%" height="auto" />
                                <h5 className="portfolio-title">Four Square Gospel Church</h5>
                                <p className="portfolio-title pt-3 pb-4" style={{ fontWeight:"normal", marginTop:"1px", fontSize:"15px" }}>Ejigbo, Lagos</p>
                            </Link>
                        </div>
                        <div className="col-lg-4 col-md-12  col-sm-12 col-xs-12 portfolio-container" style={{ marginTop: "0.5%" }}>
                            <Link to="/Elepe">
                                <img className="portfolio-grayscale" src='https://res.cloudinary.com/toniedesignsatelier/image/upload/v1595933888/Portfolio/Commercial/Elepe/Elepe_front_tkhk69.jpg' alt="Image loading" width="100%" height="auto" />
                                <h5 className="portfolio-title">3 bedroom flat</h5>
                                <p className="portfolio-title pt-3 pb-4" style={{ fontWeight:"normal", marginTop:"1px", fontSize:"15px" }}>Elepe, Ikorodu, Lagos</p>
                            </Link>
                        </div>
                        <div className="col-lg-4 col-md-12  col-sm-12 col-xs-12 portfolio-container-potrait">
                            <Link to="/PrivatePortharcout">
                                <img className="portfolio-grayscale" src='https://res.cloudinary.com/toniedesignsatelier/image/upload/v1595845693/Portfolio/Commercial/Private%20House%20Port%20Harcout/Private_House_port_harcout_e4yc8m.jpg' alt="Image loading" width="100%" height="auto" />
                                <h5 className="portfolio-title">Residential house</h5>
                                <p className="portfolio-title pt-3 pb-4" style={{ fontWeight:"normal", marginTop:"1px", fontSize:"15px" }}>Port Harcourt</p>
                            </Link>
                        </div>
                        {/* ========================================================================= 3rd line ================================================================================== */}

                    </div>
                    <div className="row" style={{ marginTop: "0.5%" }}>
                        <div className="col-lg-4 col-md-12  col-sm-12 col-xs-12 portfolio-container">
                            <Link to="/Detached">
                                <img className="portfolio-grayscale" src='https://res.cloudinary.com/toniedesignsatelier/image/upload/v1595942807/Portfolio/Commercial/Detached/ajah_terrace_front_bsrpmp.jpg' alt="Image loading" width="100%" height="auto%" />
                                <h5 className="portfolio-title">Detached buildings</h5>
                                <p className="portfolio-title pt-3 pb-4" style={{ fontWeight:"normal", marginTop:"1px", fontSize:"15px" }}>Lekki, Lagos</p>
                            </Link>
                        </div>

                        <div className="col-lg-4 col-md-12  col-sm-12 col-xs-12 portfolio-container">
                            <Link to="/HostelOwerri">
                                <img className="portfolio-grayscale" src='https://res.cloudinary.com/toniedesignsatelier/image/upload/v1595938859/Portfolio/Commercial/Hostel%20Owerri/hostel_front_t82qjs.jpg' alt="Image loading" width="100%" height="auto" />
                                <h5 className="portfolio-title">Hostel Accomodation</h5>
                                <p className="portfolio-title pt-3 pb-4" style={{ fontWeight:"normal", marginTop:"1px", fontSize:"15px" }}>Owerri</p>
                            </Link>
                        </div>
                        <div className="col-lg-4 col-md-12  col-sm-12 col-xs-12 portfolio-container">
                            <Link to="/SemiDetached">
                                <img className="portfolio-grayscale" src='https://res.cloudinary.com/toniedesignsatelier/image/upload/v1595942287/Portfolio/Commercial/Detached/terrace_front_vr4d6s.jpg' alt="Image loading" width="100%" height="auto" />
                                <h5 className="portfolio-title">Semi detached buildings</h5>
                                <p className="portfolio-title pt-3 pb-4" style={{ fontWeight:"normal", marginTop:"1px", fontSize:"15px" }}>Lekki, Lagos</p>
                            </Link>
                        </div>
                        <div className="col-lg-4 col-md-12  col-sm-12 col-xs-12 portfolio-container-potrait">
                            <Link to="/WarriHotel">
                                <img className="portfolio-grayscale" src='https://res.cloudinary.com/toniedesignsatelier/image/upload/v1595944674/Portfolio/Commercial/Hotel%20in%20Warri/hotel_front_oebvpa.jpg' alt="Image loading" width="100%" height="auto" />
                                <h5 className="portfolio-title">3 Star hotel</h5>
                                <p className="portfolio-title pt-3 pb-4" style={{ fontWeight:"normal", marginTop:"1px", fontSize:"15px" }}>Warri, Delta</p>
                            </Link>
                        </div>
                        <div className="col-lg-4 col-md-12  col-sm-12 col-xs-12 portfolio-container" style={{ marginTop: "0.5%" }}>
                            <Link to="/omole">
                                <img className="portfolio-grayscale" src='https://res.cloudinary.com/toniedesignsatelier/image/upload/v1595946120/Portfolio/Commercial/Jakande/jakande_front_qservy.jpg' alt="Image loading" width="100%" height="auto" />
                                <h5 className="portfolio-title">Block of flats</h5>
                                <p className="portfolio-title pt-3 pb-4" style={{ fontWeight:"normal", marginTop:"1px", fontSize:"15px" }}>Omole, Lagos</p>
                            </Link>
                        </div>
                        <div className="col-lg-4 col-md-12  col-sm-12 col-xs-12 portfolio-container-potrait">
                            <Link to="/Shimawa">
                                <img className="portfolio-grayscale" src='https://res.cloudinary.com/toniedesignsatelier/image/upload/v1595945092/Portfolio/Commercial/Shimawa/Shimawa_ceg4hg.jpg' alt="Image loading" width="100%" height="auto" />
                                <h5 className="portfolio-title">Residential Building</h5>
                                <p className="portfolio-title pt-3 pb-4" style={{ fontWeight:"normal", marginTop:"1px", fontSize:"15px" }}>Shimawa, Lagos</p>
                            </Link>
                        </div>
                    </div>
                    <div className="row" style={{ marginTop: "0.5%" }}>
                        <div className="col-lg-4 col-md-12  col-sm-12 col-xs-12 portfolio-container">
                            <Link to="/Oluwole">
                                <img className="portfolio-grayscale" src='https://res.cloudinary.com/toniedesignsatelier/image/upload/v1596223222/Portfolio/Commercial/Oluwole/oluwole_front_dnasut.jpg' alt="Image loading" width="100%" height="auto%" />
                                <h5 className="portfolio-title">3 bedroom flat</h5>
                                <p className="portfolio-title pt-3 pb-4" style={{ fontWeight:"normal", marginTop:"1px", fontSize:"15px" }}>Oluyole, Ibadan</p>
                            </Link>
                        </div>
                        <div className="col-lg-4 col-md-12  col-sm-12 col-xs-12 portfolio-container">
                            <Link to="/denois">
                                <img className="portfolio-grayscale" src='https://res.cloudinary.com/toniedesignsatelier/image/upload/v1596226335/Portfolio/Commercial/Denoiser/re2.Denoiser_n8lb10.jpg' alt="Image loading" width="100%" height="auto" />
                                <h5 className="portfolio-title">Block of flats</h5>
                                <p className="portfolio-title pt-3 pb-4" style={{ fontWeight:"normal", marginTop:"1px", fontSize:"15px" }}>Wuse 2, Abuja</p>
                            </Link>
                        </div>
                        <div className="col-lg-4 col-md-12  col-sm-12 col-xs-12 portfolio-container">
                            <Link to="/niger">
                                <img className="portfolio-grayscale" src='https://res.cloudinary.com/toniedesignsatelier/image/upload/v1596226015/Portfolio/Commercial/Noname/Untitled_27_wv5eut.jpg' alt="Image loading" width="100%" height="auto" />
                                <h5 className="portfolio-title">Block of flats</h5>
                                <p className="portfolio-title pt-3 pb-4" style={{ fontWeight:"normal", marginTop:"1px", fontSize:"15px" }}>Minna, Niger</p>
                            </Link>
                        </div>
                        
                    </div>


                </div>
            </div>
        )
    }
}

export default Portfolio;
import React, {Component} from 'react';
// import Slider from 'react-slick';
// import Slides from '.../components/Slides';
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/custom-animations/open-animation.css';
import 'react-awesome-slider/dist/styles.css';
import withAutoplay from 'react-awesome-slider/dist/autoplay' ;
import { Link } from 'react-router-dom';
import { SRLWrapper } from "simple-react-lightbox";


const AutoplaySlider = withAutoplay(AwesomeSlider)
class Oluwole extends Component {
    render () {
        return (
            <div className="container" style={{ marginTop:"-3%" }}>
                <div className="row">
                    <div className='col-12'>
                    <Link to="/portfolio"  style={{ color:"red" }} className="float-right"><h4><i className="fa fa-arrow-left" /></h4></Link>
                    </div>
                    {/* <div className='col-12'>
                    <h6 style={{ color:"white" }}>Construction Of A duplex and 4 Bedroom Flat at Abeokuta</h6>
                    </div> */}
                </div>
                <div className="row">
                    <div className="col-lg-2 col-md-12 col-sm-12 col-xs-12">
                    </div>
                    <div className="col-lg-8 col-md-12 col-sm-12 col-xs-12">
                        <h6 style={{ color:"white" }}>3D Visualization of a 3 bedroom flat at Oluyole, Ibadan</h6>
                        <AutoplaySlider animation="openAnimation"
                            play = {true}
                            cancelOnInteraction={false}
                            interval={3000}
                            bullets = {true}
                            >
                            <div data-src="https://res.cloudinary.com/toniedesignsatelier/image/upload/v1596223222/Portfolio/Commercial/Oluwole/oluwole_front_dnasut.jpg" alt="Image 1">
                            </div>
                            <div data-src="https://res.cloudinary.com/toniedesignsatelier/image/upload/v1596224461/Portfolio/Commercial/Oluwole/oluwode_22_cdoiit.png" alt="Image 2"/>
                            
                        </AutoplaySlider>
                    </div>
                    <div className="col-lg-2 col-md-12 col-sm-12- col-xs-12">
                    </div>
                </div>
                {/* <div className="row"> */}
                {/* <div className="col-4">
                        <img src="https://res.cloudinary.com/toniedesignsatelier/image/upload/v1595899854/Portfolio/Commercial/Abeokuta/Private-Residential-Apartment-in-Abeokuta-2_s0t0i1.jpg" alt="Abeokuta Stuff" width="100%" height="auto" />
                        </div> */}

                {/* </div> */}
            </div>
        )
    }
}
export default Oluwole;